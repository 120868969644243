import CONFIG from './config'

const API_ENDPOINT = {
  LOGIN: `${CONFIG.BASE_URL}/auth/admin/login`,
  GET_PRODUCTS: `${CONFIG.BASE_URL}/products`,
  GET_PRODUCT_BY_ID: (id: string) => `${CONFIG.BASE_URL}/products/${id}`,
  PATCH_PRODUCT: (id: string) => `${CONFIG.BASE_URL}/products/${id}`,
  DELETE_PRODUCT_BY_ID: (id: string) => `${CONFIG.BASE_URL}/products/${id}`,
  CREATE_PRODUCT: `${CONFIG.BASE_URL}/products`,
  GET_CATEGORIES: `${CONFIG.BASE_URL}/categories`,
  GET_CATEGORIES_BY_ID: (id: string) => `${CONFIG.BASE_URL}/categories/${id}`,
  PATCH_CATEGORY: (id: string) => `${CONFIG.BASE_URL}/categories/${id}`,
  DELETE_CATEGORY_BY_ID: (id: string) => `${CONFIG.BASE_URL}/categories/${id}`,
  CREATE_CATEGORY: `${CONFIG.BASE_URL}/categories`,
  GET_NEWS: `${CONFIG.BASE_URL}/news`,
  GET_NEWS_BY_ID: (id: string) => `${CONFIG.BASE_URL}/news/${id}`,
  PATCH_NEWS: (id: string) => `${CONFIG.BASE_URL}/news/${id}`,
  DELETE_NEWS_BY_ID: (id: string) => `${CONFIG.BASE_URL}/news/${id}`,
  // This Endpoint will be used
  GET_ADMIN: `${CONFIG.BASE_URL}/admin/me`,
  UPLOAD_IMAGE: `${CONFIG.BASE_URL}/file/image`,
  MUTATION_FILE_TEMPLATE: `${CONFIG.BASE_URL}/file/template`,
  UPLOAD_IMAGE_AI_MUTATION_THEME: `${process.env.REACT_APP_AI_THEME_MUTATION_IMAGE_BASE_URL}/file/image`,
  UPLOAD_PDF: `${CONFIG.BASE_URL}/file/pdf`,
  UPLOAD_VIDEO: `${CONFIG.BASE_URL}/file/video`,
  UPLOAD_FONT: `${CONFIG.BASE_URL}/file/font`,
  UPDATE_ADMIN: `${CONFIG.BASE_URL}/auth/admin/update`,
  UPDATE_COMPANY: (id: number) => `${CONFIG.BASE_URL}/company/${id}`,
  CHANGE_ADMIN_PASSWORD: `${CONFIG.BASE_URL}/auth/admin/change_password`,
  CHANGE_COMPANY_PASSWORD: `${CONFIG.BASE_URL}/auth/company/change_password`,
  POST_OTP_SEND: `${CONFIG.BASE_URL}/otp/send`,
  POST_OTP_VERIFY: `${CONFIG.BASE_URL}/otp/verify`,
  COMPANY: `${CONFIG.BASE_URL}/company`,
  COMPANY_DETAIL: (id: number) => `${CONFIG.BASE_URL}/company/${id}`,
  PACKET_LIST: `${CONFIG.BASE_URL}/packet`,
  PACKET_DETAIL: (id: number) => `${CONFIG.BASE_URL}/packet/${id}`,
  PAYMENT_METHOD_LIST: `${CONFIG.BASE_URL}/payment_method`,
  PAYMENT_METHOD_DETAIL: (id: number) => `${CONFIG.BASE_URL}/payment_method/${id}`,
  COMPANY_REGISTER: `${CONFIG.BASE_URL}/auth/company/register`,
  COMPANY_SUBSCRIPTION: `${CONFIG.BASE_URL}/company/subscription`,
  COMPANY_SUBSCRIPTION_BUY: `${CONFIG.BASE_URL}/company/subscription/buy`,
  THEME_CATEGORY: `${CONFIG.BASE_URL}/theme_category`,
  // Voucher
  VOUCHER_GET: `${CONFIG.BASE_URL}/voucher`,
  VOUCHER_GENERATE: `${CONFIG.BASE_URL}/voucher`,
  VOUCHER_UPDATE: (id: number) => `${CONFIG.BASE_URL}/voucher/${id}`,
  VOUCHER_DELETE: (id: number) => `${CONFIG.BASE_URL}/voucher/${id}`,
}

export default API_ENDPOINT
