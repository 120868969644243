import React from 'react'
import {GoogleMap, Marker, useJsApiLoader} from '@react-google-maps/api'

const containerStyle = {
  width: '100%',
  height: '400px',
}

type PropsType = {
  center: {
    lat: number
    lng: number
  }
  pos?: {
    lat: number
    lng: number
  }
  setPos: any
}

function MapComponent(props: PropsType) {
  const {center, pos, setPos} = props

  const {isLoaded} = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_API_KEY_MAPS || '',
  })

  const [map, setMap] = React.useState<any>(null)

  const onLoad = React.useCallback(function callback(map: {
    fitBounds: (arg0: google.maps.LatLngBounds) => void
  }) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(center)
    map.fitBounds(bounds)
    setMap(map)
  },
  [])

  const onUnmount = React.useCallback(function callback(map: any) {
    setMap(null)
  }, [])

  const mapClick = (e: any) => {
    const lat = e.latLng.lat()
    const lng = e.latLng.lng()
    const pos = {lat, lng}
    setPos(pos)
  }

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={11}
      onLoad={onLoad}
      onUnmount={onUnmount}
      onClick={mapClick}
      options={{
        streetViewControl: false,
        mapTypeControl: false,
      }}
    >
      {/* Child components, such as markers, info windows, etc. */}
      {pos ? <Marker position={pos}></Marker> : <></>}
    </GoogleMap>
  ) : (
    <></>
  )
}

export default React.memo(MapComponent)
