/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
// import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {sendOTP, verifyOTP} from '../core/_requests'
// import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useAuth} from '../core/Auth'
import {AuthModelAdmin, AuthModelCompany} from '../core/_models'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {Toaster} from '../../../lib/Toaster'
import {changePassword} from '../../accounts/components/password/Password'
import axios from 'axios'
import API_ENDPOINT from '../../../configs/apiEndpoint'

const loginSchema = Yup.object().shape({
  email: Yup.string().email().required('Email is required'),
  otp: Yup.string().length(6).required('OTP is required'),
  role: Yup.string().required('Role is required'),
})

const changePassSchema = Yup.object().shape({
  password: Yup.string().required('New Password is required'),
})

const initialValues = {
  email: '',
  otp: '',
  role: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function VerifyOTP() {
  const [loading, setLoading] = useState(false)
  const [loadingResend, setLoadingResend] = useState(false)
  const [modal, setModal] = useState(false)
  const [timeLeft, setTimeLeft] = useState(0)
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const idQuery = searchParams.get('id')
  const emailQuery = searchParams.get('email')
  const roleQuery = searchParams.get('role')
  const registrationQuery = searchParams.get('registration')
  const resetQuery = searchParams.get('reset')
  const STORAGE_KEY_OTP = 'timer-otp'
  const storageTimer = localStorage.getItem(STORAGE_KEY_OTP)
  const initialTimer = 180
  const timeWaitOTPInSecond = storageTimer ? JSON.parse(storageTimer)?.timer : 0
  const {saveAuth, setCurrentUser, tempAuth, saveTempAuth} = useAuth()
  useEffect(() => {
    setTimeLeft(timeWaitOTPInSecond)
    // if (Boolean(resetQuery) === true) {
    //   setModal(true)
    // }
  }, [])
  useEffect(() => {
    // exit early when we reach 0
    if (!timeLeft) {
      localStorage.removeItem(STORAGE_KEY_OTP)
      return
    }
    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1)
      localStorage.setItem(STORAGE_KEY_OTP, JSON.stringify({timer: timeLeft}))
    }, 1000)

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId)
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft])

  const formik = useFormik({
    initialValues: {
      email: emailQuery,
      role: roleQuery,
      otp: '',
    },
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const response = await verifyOTP(values.email || '', values.otp || '', values.role || '')

        const {data: result} = response

        let currUser: AuthModelAdmin | AuthModelCompany

        if (result?.code === 200) {
          if (roleQuery === 'SUPER_ADMIN') {
            currUser = {
              ...tempAuth?.data,
              token: result?.data?.token,
              user: {
                ...tempAuth?.data?.user,
                role: tempAuth?.data?.user?.role || 'SUPER_ADMIN',
              },
            } as AuthModelAdmin
          } else {
            currUser = {
              ...tempAuth?.data,
              token: result?.data?.token,
              user: {
                ...tempAuth?.data?.user,
                role: tempAuth?.data?.user?.role || 'COMPANY',
              },
            } as AuthModelCompany
          }
          setSubmitting(false)
          saveTempAuth({code: 200, message: '', data: currUser})
          setLoading(false)
          if (Boolean(resetQuery) === true) {
            setModal(true)
            return
          }

          if (Boolean(registrationQuery) === true) {
            console.log('company form', result.data)
            navigate(`/auth/company-form?id=${idQuery}&email=${values.email}`, {replace: true})
            return
          }
          Toaster({icon: 'success', html: 'OTP correct!', timer: 1500}).then(() => {
            setCurrentUser({...currUser})
            saveAuth({code: tempAuth?.code || 0, message: tempAuth?.message || '', data: currUser})
            saveTempAuth(undefined)
            localStorage.setItem(STORAGE_KEY_OTP, JSON.stringify({timer: 0}))
          })
        }
      } catch (error: any) {
        Toaster({
          icon: 'error',
          html: error?.response?.data?.message || 'OTP invalid!',
          timer: 1500,
        })
        console.error(error)
        saveAuth(undefined)
        setStatus(error?.response?.data?.message || 'Verifikasi OTP gagal!')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  const formikChangePass = useFormik({
    initialValues: {
      password: '',
    },
    validationSchema: changePassSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        // const result = await changePassword({password: values.password})
        let result: any
        if (roleQuery === 'SUPER_ADMIN') {
          result = await axios.put(
            API_ENDPOINT.CHANGE_ADMIN_PASSWORD,
            {
              password: values.password,
            },
            {
              headers: {
                Authorization: `Bearer ${tempAuth?.data?.token}`,
              },
            }
          )
        } else if (roleQuery === 'COMPANY') {
          result = await axios.put(
            API_ENDPOINT.CHANGE_COMPANY_PASSWORD,
            {
              password: values.password,
            },
            {
              headers: {
                Authorization: `Bearer ${tempAuth?.data?.token}`,
              },
            }
          )
        }

        if (result?.data?.code === 200) {
          Toaster({icon: 'success', html: 'Password has successfully changed!', timer: 1500})
          setLoading(false)
          setSubmitting(false)
          setTimeout(() => {
            if (roleQuery === 'SUPER_ADMIN') {
              navigate('/auth/admin')
            } else if (roleQuery === 'company') {
              navigate('/auth')
            } else {
              navigate('/auth')
            }
          }, 1700)
        }
      } catch (error) {
        Toaster({icon: 'error', html: 'Change Password Failed!', timer: 1500})
        console.error(error)
        saveAuth(undefined)
        setStatus('Change password failed!')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  function secondsToHms(d: any) {
    d = Number(d)
    var h = Math.floor(d / 3600)
    var m = Math.floor((d % 3600) / 60)
    var s = Math.floor((d % 3600) % 60)

    var hDisplay = h > 0 ? h + (h == 1 ? ' hour, ' : ' hours, ') : ''
    var mDisplay = m > 0 ? m + (m == 1 ? ' minute, ' : ' minutes, ') : ''
    var sDisplay = s > 0 ? s + (s == 1 ? ' second' : ' seconds') : ''
    return hDisplay + mDisplay + sDisplay
  }

  const handleResendOTP = async () => {
    try {
      setLoadingResend(true)
      const {data: result} = await sendOTP(emailQuery || '', roleQuery || '')
      setTimeLeft(initialTimer)

      if (result?.code === 200) {
        Toaster({icon: 'success', html: 'OTP was successfully sent!', timer: 1500})
      }
      setLoadingResend(false)
    } catch (error) {
      Toaster({icon: 'error', html: 'Failed send OTP!, resend later...', timer: 1500})
      console.error(error)
      saveAuth(undefined)
      setLoadingResend(false)
    }
  }

  return (
    <>
      {!modal ? (
        <form
          className='form w-100'
          onSubmit={formik.handleSubmit}
          noValidate
          id='kt_login_signin_form'
        >
          <div className='text-center mb-11'>
            <h1 className='text-dark fw-bolder mb-3'>Verify OTP</h1>
            {/* <div className='text-gray-500 fw-semibold fs-6'>As Administrator</div> */}
          </div>

          {formik.status ? (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          ) : (
            ''
          )}

          {/* begin::Form group */}
          <div className='fv-row mb-8' style={{display: 'none'}}>
            <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
            <input
              placeholder='Your Email'
              {...formik.getFieldProps('email')}
              className={clsx(
                'form-control bg-transparent',
                {'is-invalid': formik.touched.email && formik.errors.email},
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
              type='text'
              name='email'
              autoComplete='off'
            />
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.email}</span>
                </div>
              </div>
            )}
          </div>
          <div className='fv-row mb-8' style={{display: 'none'}}>
            <label className='form-label fs-6 fw-bolder text-dark'>role</label>
            <input
              placeholder='Your OTP'
              {...formik.getFieldProps('role')}
              className={clsx(
                'form-control bg-transparent',
                {'is-invalid': formik.touched.role && formik.errors.role},
                {
                  'is-valid': formik.touched.role && !formik.errors.role,
                }
              )}
              autoComplete='off'
            />
            {formik.touched.role && formik.errors.role && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.role}</span>
                </div>
              </div>
            )}
          </div>
          <div className='fv-row mb-8'>
            <label className='form-label fs-6 fw-bolder text-dark'>OTP</label>
            <input
              placeholder='Your OTP'
              {...formik.getFieldProps('otp')}
              className={clsx(
                'form-control bg-transparent',
                {'is-invalid': formik.touched.otp && formik.errors.otp},
                {
                  'is-valid': formik.touched.otp && !formik.errors.otp,
                }
              )}
              autoComplete='off'
            />
            {formik.touched.otp && formik.errors.otp && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.otp}</span>
                </div>
              </div>
            )}
          </div>

          <div className='d-grid mb-10'>
            <button
              type='submit'
              id='kt_sign_in_submit'
              className='btn btn-primary'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {!loading && <span className='indicator-label'>Submit OTP</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
            <div className='text-gray-500 text-center fw-semibold fs-6 my-3'>
              Not Received OTP? <br />
            </div>
            <button
              disabled={timeLeft ? true : false}
              onClick={handleResendOTP}
              type='button'
              className='btn btn-success'
            >
              {!loadingResend && <span className='indicator-label'>Resend OTP</span>}
              {loadingResend && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
            {timeLeft !== 0 && (
              <div className='text-danger text-center fw-semibold fs-6 my-3'>
                Can resend OTP after {secondsToHms(timeLeft)}
              </div>
            )}
          </div>
          {/* end::Action */}
        </form>
      ) : (
        <form
          className='form w-100'
          onSubmit={formikChangePass.handleSubmit}
          noValidate
          id='kt_login_signin_form'
        >
          <div className='text-center mb-11'>
            <h1 className='text-dark fw-bolder mb-3'>Change Password</h1>
            {/* <div className='text-gray-500 fw-semibold fs-6'>As Administrator</div> */}
          </div>

          {formikChangePass.status ? (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formikChangePass.status}</div>
            </div>
          ) : (
            ''
          )}

          <div className='fv-row mb-8'>
            <label className='form-label fs-6 fw-bolder text-dark'>New Password</label>
            <input
              placeholder='Your New Password'
              {...formikChangePass.getFieldProps('password')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid':
                    formikChangePass.touched.password && formikChangePass.errors.password,
                },
                {
                  'is-valid':
                    formikChangePass.touched.password && !formikChangePass.errors.password,
                }
              )}
              autoComplete='off'
            />
            {formikChangePass.touched.password && formikChangePass.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formikChangePass.errors.password}</span>
                </div>
              </div>
            )}
          </div>

          <div className='d-grid mb-10'>
            <button
              type='submit'
              id='kt_sign_in_submit'
              className='btn btn-primary'
              disabled={formikChangePass.isSubmitting || !formikChangePass.isValid}
            >
              {!loading && <span className='indicator-label'>Submit</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
          {/* end::Action */}
        </form>
      )}
      {/* {modal && <ChangePasswordModal />} */}
    </>
  )
}
