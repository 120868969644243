/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import ReactApexChart from 'react-apexcharts'
import {DATE_FILTER} from '../../../../app/pages/dashboard/models/_model'
import dayjs from 'dayjs'

export const DatePick = ({
  onChangeDateStart,
  onChangeDateEnd,
  value,
  keyProperty,
}: {
  value: DATE_FILTER
  keyProperty: string
  onChangeDateStart?: (props: React.ChangeEvent<HTMLInputElement>) => void
  onChangeDateEnd?: (props: React.ChangeEvent<HTMLInputElement>) => void
}) => {
  const refDateStart = useRef<any>(null)
  const refDateEnd = useRef<any>(null)
  const hasValueDateStart = Boolean(value[keyProperty as keyof DATE_FILTER].start_at)
  const hasValueDateEnd = Boolean(value[keyProperty as keyof DATE_FILTER].end_at)
  return (
    <div className='d-flex justify-content-center align-items-center gap-3'>
      <div className='d-flex flex-column'>
        <label>Start</label>
        <input
          value={
            hasValueDateStart
              ? dayjs(value[keyProperty as keyof DATE_FILTER].start_at).format('YYYY')
              : dayjs().format('YYYY') // Mengambil tahun sekarang jika tidak ada nilai
          }
          className='form-control form-control-sm form-control-solid mb-3 mb-lg-0'
          ref={refDateStart}
          onClick={() => refDateStart.current?.showPicker()}
          onChange={onChangeDateStart}
          type='number'
          min={1900} // Batas minimum tahun
          max={dayjs().year()} // Batas maksimum tahun (tahun sekarang)
          step='1' // Langkah increment tahun satu per satu
        />

        {/* <input
          value={
            hasValueDateStart
              ? dayjs(value[keyProperty as keyof DATE_FILTER].start_at).format('YYYY-MM-DD')
              : dayjs().startOf('week').format('YYYY-MM-DD')
          }
          className='form-control form-control-sm form-control-solid mb-3 mb-lg-0'
          ref={refDateStart}
          /// https://stackoverflow.com/a/74687522/18038473
          /// how to open date picker modal user useRef
          /// By eroironico : https://stackoverflow.com/users/17264570/eroironico
          onClick={() => refDateStart.current?.showPicker()}
          onChange={onChangeDateStart}
          type='date'
        /> */}
      </div>
      <div className='d-flex flex-column'>
        <label>End</label>
        <input
          value={
            hasValueDateEnd
              ? dayjs(value[keyProperty as keyof DATE_FILTER].end_at).format('YYYY-MM-DD')
              : dayjs().endOf('week').format('YYYY-MM-DD')
          }
          className='form-control form-control-sm form-control-solid mb-3 mb-lg-0'
          ref={refDateEnd}
          onChange={onChangeDateEnd}
          onClick={() => refDateEnd.current?.showPicker()}
          type='date'
        />
      </div>
    </div>
  )
}

function CustomChart({
  setDate = () => {},
  keyProperty,
  dataItem,
  title = 'Text here',
  date,
  keyChart = [],
}: {
  setDate: (props: any) => any
  keyProperty: string
  title: string
  date: any
  dataItem: any
  keyChart: string[]
}) {
  const [data, setData] = useState([])
  const [category, setCategory] = useState([])
  const [active, setActive] = useState('week')
  const handleChangeDateOption = (arg: string) => {
    setActive(arg)
    setDate((prev: DATE_FILTER) => ({
      ...prev,
      [keyProperty]: {
        ...prev[keyProperty as keyof DATE_FILTER],
        start_at: dayjs()
          .startOf(arg as any)
          .toISOString(),
        end_at: dayjs()
          .endOf(arg as any)
          .toISOString(),
      },
    }))
  }

  useEffect(() => {
    if (Boolean(dataItem)) {
      setData(() => {
        return keyChart?.map((itemChart) => ({
          name: itemChart,
          data: dataItem[itemChart]?.map((item: any) => item?.count),
        })) as any
      })
      setCategory(() => {
        return keyChart?.map((itemChart) => ({
          name: itemChart,
          category: dataItem[itemChart]?.map((item: any) => item?.created_at),
        })) as any
      })
    }
  }, [dataItem])
  return (
    <div className={`card`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{title}</span>

          <span className='text-muted fw-semibold fs-7'>-</span>
        </h3>

        {/* begin::Toolbar */}
        <div className='card-toolbar d-flex flex-column mt-10' data-kt-buttons='true'>
          <DatePick
            keyProperty={keyProperty}
            value={date}
            onChangeDateStart={(i) => {
              setDate((prev: DATE_FILTER) => ({
                ...prev,
                [keyProperty]: {
                  ...prev[keyProperty as keyof DATE_FILTER],
                  start_at: dayjs(i.target.value).startOf('day').toISOString(),
                },
              }))
            }}
            onChangeDateEnd={(i) => {
              setDate((prev: DATE_FILTER) => ({
                ...prev,
                [keyProperty]: {
                  ...prev[keyProperty as keyof DATE_FILTER],
                  end_at: dayjs(i.target.value).endOf('day').toISOString(),
                },
              }))
            }}
          />
          <div className='mt-10'>
            {['Year', 'month', 'week'].map((item) => (
              <a
                key={item}
                onClick={() => handleChangeDateOption(item.toLowerCase())}
                className={`btn btn-sm btn-color-muted btn-active btn-active-primary ${
                  item.toLowerCase() === active && 'active'
                }  px-4 me-1`}
                id='kt_charts_widget_7_year_btn'
              >
                {item}
              </a>
            ))}
          </div>
        </div>
        {/* end::Toolbar */}
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      {keyChart?.map((item, index) => {
        const dataChart = data?.filter((itemData: any) => itemData?.name === item)
        const categoryChart: any = category?.find(
          (itemCategory: any) => itemCategory?.name === item
        )
        return (
          <div key={index} className='card-body pt-0'>
            <div className='card-header border-0'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold fs-6'>{item}</span>
              </h3>
            </div>
            {/* begin::Chart */}
            <ReactApexChart
              options={{
                chart: {
                  height: 350,
                  type: 'area',
                  toolbar: {
                    show: false,
                  },
                },
                dataLabels: {
                  enabled: false,
                },
                stroke: {
                  curve: 'smooth',
                },
                xaxis: {
                  type: 'category',
                  categories: categoryChart?.category,
                  labels: {
                    show: true,
                    formatter: function (val) {
                      return dayjs(val).format('DD MMM YYYY')
                    },
                  },
                },
                tooltip: {
                  x: {
                    show: false,
                  },
                  enabled: false,
                },
              }}
              series={dataChart}
              type='area'
              height={350}
            />
            {/* end::Chart */}
          </div>
        )
      })}
      {/* end::Body */}
    </div>
  )
}

export default CustomChart
