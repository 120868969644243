import {useState} from 'react'
import { DATE_FILTER } from '../models/_model'

const useDate = () => {
  const [date, setDate] = useState<DATE_FILTER>({
    map: {
      start_at: '',
      end_at: '',
    },
    company: {
      start_at: null,
      end_at: null,
    },
    product: {
      start_at: null,
      end_at: null,
    },
    theme: {
      start_at: null,
      end_at: null,
    },
    machine: {
      start_at: null,
      end_at: null,
    },
    accounting: {
      start_at: null,
      end_at: null,
    },
    packet: {
      start_at: null,
      end_at: null,
    },
    chart_company: {
      start_at: null,
      end_at: null,
    },
  })

  return {date, setDate}
}

export default useDate
