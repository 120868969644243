import {AuthModel} from './_models'
import {AxiosResponse, AxiosError} from 'axios'

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'
const AUTH_TEMP_LOCAL_STORAGE_KEY = 'kt-auth-react-temporary'
const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue)
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}
const getTempAuth = (): any | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_TEMP_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: any = JSON.parse(lsValue)
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setTempAuth = (auth: any) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_TEMP_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeTempAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_TEMP_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

export function setupAxios(axios: any) {
  axios.defaults.headers.Accept = 'application/json'
  axios.defaults.headers['Access-Control-Allow-Origin'] = '*'
  axios.interceptors.request.use(
    (config: {headers: {Authorization: string}}) => {
      const auth = getAuth()
      const tempAuth = getTempAuth()
      if (auth && auth?.data?.token) {
        config.headers.Authorization = `Bearer ${auth?.data?.token}`
      }

      if (tempAuth && tempAuth?.data?.token) {
        config.headers.Authorization = `Bearer ${tempAuth?.data?.token}`
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )
  axios.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error: AxiosError) => {
      const statusCode = error?.response?.status
      const auth = getAuth()
      if (
        error?.config?.url?.includes(`${process.env.REACT_APP_API_URL}/auth/admin`) ||
        error?.config?.url?.includes(`${process.env.REACT_APP_API_URL}/company/`)
      ) {
        throw error
      } else {
        if (statusCode === 401) {
          auth?.data?.user?.role === 'SUPER_ADMIN'
            ? setTimeout(() => {
                removeAuth()
                window.location.href = '/auth/admin'
              }, 200)
            : setTimeout(() => {
                removeAuth()
                window.location.href = '/auth'
              }, 200)
        }
      }

      throw error
    }
  )
}

export {
  getAuth,
  setAuth,
  removeAuth,
  getTempAuth,
  setTempAuth,
  removeTempAuth,
  AUTH_TEMP_LOCAL_STORAGE_KEY,
  AUTH_LOCAL_STORAGE_KEY,
}
