/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {KTIcon} from '../../../helpers'
import {Dropdown1} from '../../content/dropdown/Dropdown1'
import {DatePick} from './CustomChart'
import dayjs from 'dayjs'
import {DATE_FILTER} from '../../../../app/pages/dashboard/models/_model'
import {useState} from 'react'

function CustomWidgetMix({
  title,
  data = [],
  keyProperty,
  setDate = () => {},
  date,
}: {
  title: string
  date: any
  data: any[]
  keyProperty: string
  setDate: (props?: any) => void
}) {
  const [active, setActive] = useState('week')
  const handleChangeDateOption = (arg: string) => {
    setActive(arg)
    setDate((prev: DATE_FILTER) => ({
      ...prev,
      [keyProperty]: {
        ...prev[keyProperty as keyof DATE_FILTER],
        start_at: dayjs()
          .startOf(arg as any)
          .toISOString(),
        end_at: dayjs()
          .endOf(arg as any)
          .toISOString(),
      },
    }))
  }
  return (
    <div className={`card`}>
      {/* begin::Header  */}
      <div className={`card-header border-0  py-8`}>
        <div className='d-flex justify-content-between align-items-center w-100'>
          <h3 className='card-title fw-bold text-dark'>{title}</h3>
          <div className=''>
            <DatePick
              keyProperty={keyProperty}
              value={date}
              onChangeDateStart={(i) => {
                setDate((prev: DATE_FILTER) => ({
                  ...prev,
                  [keyProperty]: {
                    ...prev[keyProperty as keyof DATE_FILTER],
                    start_at: dayjs(i.target.value).startOf('day').toISOString(),
                  },
                }))
              }}
              onChangeDateEnd={(i) => {
                setDate((prev: DATE_FILTER) => ({
                  ...prev,
                  [keyProperty]: {
                    ...prev[keyProperty as keyof DATE_FILTER],
                    end_at: dayjs(i.target.value).endOf('day').toISOString(),
                  },
                }))
              }}
            />
            <div className='mt-10'>
              {['Year', 'month', 'week'].map((item) => (
                <a
                  key={item}
                  onClick={() => handleChangeDateOption(item.toLowerCase())}
                  className={`btn btn-sm btn-color-muted btn-active btn-active-primary ${
                    item.toLowerCase() === active && 'active'
                  }  px-4 me-1`}
                  id='kt_charts_widget_7_year_btn'
                >
                  {item}
                </a>
              ))}
            </div>
          </div>
        </div>

        <div className='card-toolbar'>
          {/* begin::Menu  */}
          <button
            type='button'
            className={clsx(
              'btn btn-sm btn-icon btn-color-white btn-active-white',

              'border-0 me-n3'
            )}
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTIcon iconName='category' className='fs-2' />
          </button>
          <Dropdown1 />
          {/* end::Menu  */}
        </div>
      </div>
      {/* end::Header  */}

      {/* begin::Body  */}
      <div className='card-body p-0'>
        {/* begin::Chart  */}
        <div className={`mixed-widget-12-chart card-rounded-bottom `}></div>
        {/* end::Chart  */}

        {/* begin::Stats  */}
        <div className='card-rounded bg-body mt-n10 position-relative card-px py-15'>
          {/* begin::Row  */}
          <div className='row g-0 mb-7'>
            {/* begin::Col  */}
            {data.map((item, index) => (
              <div key={index} className='col mx-5'>
                <div className='fs-6 text-gray-400'>{item?.name}</div>
                <div className='fs-2 fw-bold text-gray-800'>{item?.value}</div>
              </div>
            ))}
            {/* end::Col  */}
          </div>
          {/* end::Row  */}
        </div>
        {/* end::Stats  */}
      </div>
      {/* end::Body  */}
    </div>
  )
}

export default CustomWidgetMix
