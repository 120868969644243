/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import Swal from 'sweetalert2'
import {Toaster} from '../../../../app/lib/Toaster'

const HeaderUserMenu: FC = () => {
  const {currentUser, logout} = useAuth()

  const handleLogout = () => {
    Swal.fire({
      title: 'Konfirmasi',
      html: 'Yakin ingin logout?',
      // html: 'Yakin ingin logout?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Ya',
      cancelButtonText: 'Batalkan',
    }).then((confirm) => {
      if (confirm.isConfirmed) {
        Toaster({
          icon: 'success',
          html: '<b>Logout berhasil!</b>',
          timer: 1300,
        }).then(() => logout())
      } else {
        Toaster({
          icon: 'warning',
          html: '<b>Logout dibatalkan!</b>',
          timer: 1300,
        })
      }
    })
  }
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img
              alt='Logo'
              src={
                currentUser?.user.image
                  ? `${process.env.REACT_APP_STORAGE_URL}/images/${currentUser?.user.image}`
                  : toAbsoluteUrl('/media/avatars/blank.png')
              }
              onError={(e: any) => {
                e.target.onerror = null
                e.currentTarget.src = toAbsoluteUrl('/media/avatars/blank.png')
              }}
            />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {currentUser?.user.name}
              <KTIcon iconName='verify' className='fs-1 text-primary' />
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {currentUser?.user.email}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>
      {/* <div className='menu-item px-5'>
        <Link to={'/crafted/pages/profile'} className='menu-link px-5'>
          My Profile
        </Link>
      </div> */}

      {/* 
      <div className='menu-item px-5'>
        <a href='#' className='menu-link px-5'>
          <span className='menu-text'>My Projects</span>
          <span className='menu-badge'>
            <span className='badge badge-light-danger badge-circle fw-bolder fs-7'>3</span>
          </span>
        </a>
      </div>

      <div
        className='menu-item px-5'
        data-kt-menu-trigger='hover'
        data-kt-menu-placement='left-start'
        data-kt-menu-flip='bottom'
      >
        <a href='#' className='menu-link px-5'>
          <span className='menu-title'>My Subscription</span>
          <span className='menu-arrow'></span>
        </a>

        <div className='menu-sub menu-sub-dropdown w-175px py-4'>
          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Referrals
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Billing
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Payments
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link d-flex flex-stack px-5'>
              Statements
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='View your statements'
              ></i>
            </a>
          </div>

          <div className='separator my-2'></div>

          <div className='menu-item px-3'>
            <div className='menu-content px-3'>
              <label className='form-check form-switch form-check-custom form-check-solid'>
                <input
                  className='form-check-input w-30px h-20px'
                  type='checkbox'
                  value='1'
                  defaultChecked={true}
                  name='notifications'
                />
                <span className='form-check-label text-muted fs-7'>Notifications</span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className='menu-item px-5'>
        <a href='#' className='menu-link px-5'>
          My Statements
        </a>
      </div>

      <div className='separator my-2'></div>

      <Languages /> */}

      <div className='menu-item px-5 my-1'>
        <Link to='/account/overview' className='menu-link px-5'>
          Pengaturan Akun
        </Link>
      </div>

      <div className='menu-item px-5'>
        <a onClick={handleLogout} className='menu-link px-5'>
          Log Out
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
