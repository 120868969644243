/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {CardsWidget7} from '../../../_metronic/partials/widgets'
import CustomChart from '../../../_metronic/partials/widgets/_new/CustomChart'
import CustomWidgetMix from '../../../_metronic/partials/widgets/_new/CustomWidgetMix'
import GoogleMaps from './components/GoogleMaps'
import useDate from './hooks/useDate'
import useFetchDataDashboard from './hooks/useFetchDataDashboard'
import {thousandFix} from '@av-utils/formatter'
import FilterDataDashboard from './components/FilterDataDashboard'

const DashboardPage: FC = () => {
  const {date, setDate} = useDate()
  const [activeMachine, setActiveMachine] = useState()
  const {dataRebuild, mapsItemIsloading, isAdmin} = useFetchDataDashboard(date, activeMachine)

  return (
    <>
      <GoogleMaps isLoading={mapsItemIsloading} data={dataRebuild.maps} />
      {isAdmin && (
        <div className='d-flex flex-column gap-10 mt-10'>
          <CustomChart
            title='Company'
            date={date}
            keyProperty='company'
            keyChart={['registered', 'banned', 'deleted']}
            dataItem={dataRebuild?.company}
            setDate={setDate}
          />
        </div>
      )}
      <div className='d-flex flex-column gap-10 mt-10'>
        <CustomChart
          title='Machine'
          date={date}
          keyProperty='machine'
          keyChart={['active', 'banned', 'inactive']}
          dataItem={dataRebuild?.machine}
          setDate={setDate}
        />
      </div>
      <div className='d-flex flex-column gap-10 mt-10'>
        <CustomChart
          title='Accounting'
          date={date}
          keyProperty='accounting'
          keyChart={['income', 'refund', 'subscription', 'withdraw']}
          dataItem={dataRebuild?.accounting}
          setDate={setDate}
        />
      </div>

      {isAdmin && (
        <div className='my-10'>
          <CustomWidgetMix
            date={date}
            keyProperty='packet'
            setDate={setDate}
            data={dataRebuild.packet}
            title='Package rekap'
          />
        </div>
      )}
      {isAdmin && (
        <div className='my-10'>
          <CustomWidgetMix
            date={date}
            keyProperty='product'
            setDate={setDate}
            data={dataRebuild.product}
            title='Product rekap'
          />
        </div>
      )}
      {isAdmin && (
        <div className='my-10'>
          <CustomWidgetMix
            date={date}
            keyProperty='theme'
            setDate={setDate}
            data={dataRebuild.theme}
            title='Theme rekap'
          />
        </div>
      )}
      <br />
      <br />
      <br />
      {!isAdmin && (
        <div className='col-12 card p-5'>
          <FilterDataDashboard
            activeMachine={activeMachine}
            setActiveMachine={setActiveMachine}
            setDate={setDate}
            keyProperty={'chart_company'}
            date={date}
            listMachine={dataRebuild?.listMachine?.items ?? []}
          />
          <div className='row g-5 g-xl-10 mb-5 mb-xl-0'>
            <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-0'>
              <CardsWidget7
                className='h-md-30 mb-5 mb-xl-0 shadow'
                description='Order'
                icon={true}
                iconColor='text-info'
                iconName={'diamonds'}
                href='/machine-order'
                stats={thousandFix(dataRebuild?.order?.order_total ?? 0)}
                labelColor='dark'
                textColor='gray-300'
              />
            </div>
            <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-0'>
              <CardsWidget7
                className='h-md-30 mb-5 mb-xl-0 shadow'
                description='Product'
                icon={true}
                iconColor='text-success'
                iconName={'package'}
                href={isAdmin ? '/product' : ''}
                stats={thousandFix(
                  (dataRebuild?.orderProduct ?? []).reduce(
                    (total: number, el: any) => total + el.total_order_amount,
                    0
                  )
                )}
                labelColor='dark'
                textColor='gray-300'
              />
            </div>
            <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-0'>
              <CardsWidget7
                className='h-md-30 mb-5 mb-xl-0 shadow'
                description='Claim'
                icon={true}
                iconName={'check-circle'}
                href={isAdmin ? '/pengguna' : ''}
                stats={thousandFix(dataRebuild?.claim?.claim_total ?? 0)}
                iconColor='text-primary'
                labelColor='dark'
                textColor='gray-300'
              />
            </div>
            <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-0'>
              <CardsWidget7
                className='h-md-30 mb-5 mb-xl-0 shadow'
                description='Refund'
                icon={true}
                iconColor='text-danger'
                iconName={'dollar'}
                href=''
                stats={thousandFix(dataRebuild?.refund?.refund_total ?? 0)}
                labelColor='dark'
                textColor='gray-300'
              />
            </div>
            <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-0'>
              <CardsWidget7
                className='h-md-30 mb-5 mb-xl-0 shadow'
                description='Voucher'
                icon={true}
                iconColor='text-danger'
                iconName={'discount'}
                href={isAdmin ? '/voucher' : ''}
                stats={thousandFix(dataRebuild?.voucher?.voucher_total_used ?? 0)}
                labelColor='dark'
                textColor='gray-300'
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
