export const rupiahFormatter = (amount: number | any) => {
  const result = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(amount);
  return result;
};

export const currency = (money: number) => {
  return new Intl.NumberFormat('id-ID', {
    style: 'currency',
    currency: 'IDR',
    minimumFractionDigits: 0,
  }).format(money)
}

export const thousand = (number: number) => {
  const options: any = {
    style: 'decimal', // Other options: 'currency', 'percent', etc.
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }
  const formattedWithOptions = number.toLocaleString('id-ID', options)
  return formattedWithOptions
}

export const thousandFix = (number: number) => {
  const options: any = {
    style: 'decimal', // Other options: 'currency', 'percent', etc.
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }
  const formattedWithOptions = number.toLocaleString('id-ID', options)
  return formattedWithOptions
}

export function isNumber(n: any) {
  return !isNaN(parseFloat(n)) && isFinite(n)
}

export function isNumberAndPoint(s: string) {
  const rgx = /^[0-9]*\.?[0-9]*$/
  return s.match(rgx)
}

export const _renderNumeric = (value: any) => {
  const number = Number(value)

  return number.toLocaleString('id-ID', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  })
}
