/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect, useCallback} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import Swal from 'sweetalert2'
import {register, updateCompany} from '../core/_requests'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'
import {useAuth} from '../core/Auth'
import {AuthModelCompany} from '../core/_models'
import {Toaster} from '../../../lib/Toaster'
import MapComponent from './../../../lib/Map'
import axios, {AxiosResponse} from 'axios'
import API_ENDPOINT from '../../../configs/apiEndpoint'
// import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

const initialValues = {
  id: 0,
  // image: '',
  name: '',
  email: '',
  address: '',
  lat: -7.308665847342366,
  lng: 112.73483276367188,
  phone: '',
  password: '',
  changepassword: '',
}

const registrationSchema = Yup.object().shape({
  id: Yup.number().required(),
  // image: Yup.string().default(''),
  name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(100, 'Maximum 100 symbols')
    .required('Name is required'),
  email: Yup.string().email().required('Email is required'),
  address: Yup.string().required('Address is required'),
  phone: Yup.string().required('Phone is required').max(15),
  lat: Yup.number().required('Latitude is required'),
  lng: Yup.number().required('Longitude is required'),
  password: Yup.string()
    .min(8, 'Minimum 8 symbols')
    .max(20, 'Maximum 50 symbols')
    .required('Password is required'),
  changepassword: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password confirmation is required')
    .oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
})

type Pos = {
  lat: number
  lng: number
}

export function Registration() {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [pwdShow, isPwdShow] = useState(false)
  const [confirmPwdShow, isConfirmPwdShow] = useState(false)
  // const [file, setFile] = useState<any>()
  // const [preview, setPreview] = useState<any>()
  const [marker, setMarker] = useState<Pos | undefined>()
  const [center, setCenter] = useState<Pos>({
    lat: initialValues.lat,
    lng: initialValues.lng,
  })
  const {saveAuth, setCurrentUser, saveTempAuth, tempAuth} = useAuth()
  const STORAGE_KEY_OTP = 'timer-otp'

  function getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition)
    } else {
      Toaster({icon: 'warning', html: 'Geolocation is not supported by this browser.', timer: 2000})
    }
  }
  function showPosition(position: any) {
    const lat = position.coords.latitude
    const lng = position.coords.longitude
    if (lat && lng) {
      setCenter({lat, lng})
    }
  }

  useEffect(() => {
    getLocation()
  }, [])

  const setPos = useCallback((pos: Pos) => {
    setMarker(pos)
    formik.setFieldValue('lat', pos.lat)
    formik.setFieldValue('lng', pos.lng)
  }, [])

  // const loadImage = (e: any) => {
  //   const image = e.target?.files[0] || ''

  //   setFile(image)

  //   setPreview(image ? URL.createObjectURL(image) : '')
  // }

  // const onUpload = async (e: any) => {
  //   e.preventDefault()
  //   setLoading(true)
  //   try {
  //     const data = new FormData()
  //     data.append('image', file)
  //     const response = await axios.post(API_ENDPOINT.UPLOAD_IMAGE, data, {
  //       headers: {
  //         'Content-Type': `multipart/form-data`,
  //       },
  //     })
  //     setPreview(`${process.env.REACT_APP_STORAGE_URL}/images/${response.data.data.url}`)
  //     formik.setFieldValue('image', response?.data?.data?.url)
  //   } catch (error) {
  //     console.log(error)
  //   } finally {
  //     setLoading(false)
  //   }
  // }

  // const onDelete = async (e: any) => {
  //   e.preventDefault()
  //   setLoading(true)

  //   Swal.fire({
  //     title: 'Konfirmasi hapus image',
  //     html: '<b>Yakin ingin menghapus image tersebut?</b>',
  //     icon: 'question',
  //     showCancelButton: true,
  //     confirmButtonText: 'Ya',
  //     cancelButtonText: 'Batalkan',
  //   })
  //     .then(async (result) => {
  //       if (result.isConfirmed) {
  //         const data = new FormData()
  //         data.append('image', file)

  //         await axios
  //           .delete(`${API_ENDPOINT.UPLOAD_IMAGE}/${formik.values.image}`, {
  //             headers: {
  //               'Content-Type': `multipart/form-data`,
  //             },
  //           })
  //           .then((response) => {
  //             console.log(response.data)
  //             Toaster({
  //               html: response.data.message || 'Berhasil menghapus image',
  //               icon: 'success',
  //               timer: 1800,
  //             }).then(() => {
  //               formik.setFieldValue('image', '')
  //               setPreview(false)
  //             })
  //           })
  //           .catch((error: any) => {
  //             Toaster({
  //               html: error?.response.data.message || 'Gagal hapus image',
  //               icon: 'error',
  //               timer: 1800,
  //             }).then(() => {
  //               setPreview(false)
  //             })
  //           })
  //       } else {
  //         Toaster({
  //           html: 'Hapus image dibatalkan',
  //           icon: 'warning',
  //           timer: 1800,
  //         })
  //       }
  //     })
  //     .catch((error: any) => {
  //       console.log(error)
  //     })
  //     .finally(() => {
  //       setLoading(false)
  //     })
  // }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...initialValues,
    },
    validationSchema: registrationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      await axios
        .post(API_ENDPOINT.COMPANY_REGISTER, values)
        .then((response: AxiosResponse) => {
          const {data} = response
          console.log(response)
          console.log(data)
          if (data?.code === 200) {
            Toaster({
              icon: 'success',
              html: data?.message || 'Berhasil update profil company',
              timer: 1500,
            }).then(() => {
              const currUser = {
                ...data?.data,
                token: data?.data?.token || '',
                user: {
                  ...data?.data?.user,
                  image: data?.data?.user?.image,
                  role: 'COMPANY',
                },
              } as AuthModelCompany
              setCurrentUser({...currUser})

              saveAuth({
                code: data?.code || 0,
                message: data?.message || '',
                data: currUser,
              })
              saveTempAuth(undefined)
              localStorage.setItem(STORAGE_KEY_OTP, JSON.stringify({timer: 0}))
            })
          }
        })
        .catch((error) => {
          setStatus(error?.response?.data?.message)
          Swal.fire({
            icon: 'error',
            title: 'Registration Failed',
            text: error?.response?.data?.message,
            showConfirmButton: false,
            timer: 1500,
          })
        })
        .finally(() => {
          setSubmitting(false)
          setLoading(false)
        })
    },
  })

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-3'>Form Company</h1>
        {/* end::Title */}

        <div className='text-gray-500 fw-semibold fs-6'>Harap Melengkapi Data Diri Company!</div>
      </div>
      {/* end::Heading */}

      {/* <div className='row mt-2 mb-6'>
        <label className='col-lg-4 col-form-label fw-bold fs-6'>Upload Image</label>
        <div className='col-lg-8 fv-row'>
          <input
            type='file'
            placeholder='Upload image'
            onChange={loadImage}
            accept='image/*'
            className={'form-control form-control-solid mb-3 mb-lg-0'}
            autoComplete='off'
          />
        </div>
      </div> */}

      {/* {preview && (
        <div className='fv-row mb-8'>
          <label className='fw-bold fs-6'>
            Preview {!formik.values.image ? 'image saat Ini' : 'image yang telah diupload'}
          </label>
          <Zoom>
            <div
              role='img'
              className='image-input-wrapper w-full'
              style={{
                backgroundColor: '#fff',
                backgroundImage: `url('${preview}')`,
                backgroundPosition: '50%',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                height: '0',
                paddingBottom: '56%',
                width: '100%',
              }}
            />
          </Zoom>
          {formik.values.image ? (
            <button
              type='button'
              className='mt-2 btn btn-danger'
              disabled={loading}
              onClick={(e: any) => onDelete(e)}
            >
              {!loading && <span className='indicator-label'>Delete image saat ini</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          ) : (
            <button
              type='button'
              className='mt-2 btn btn-primary'
              disabled={loading}
              onClick={(e: any) => onUpload(e)}
            >
              {!loading && <span className='indicator-label'>Upload</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          )}
        </div>
      )} */}

      {/* <div className='row mt-2 mb-6'>
        <label className='col-lg-4 col-form-label fw-bold fs-6'>Image URL</label>
        <div className='col-lg-8 fv-row'>
          <input
            type='text'
            placeholder='Waiting for uploaded image...'
            {...formik.getFieldProps('image')}
            className={clsx(
              'form-control form-control-lg form-control-solid mb-3 mb-lg-0',
              {
                'is-invalid': formik.touched.image && formik.errors.image,
              },
              {
                'is-valid': formik.touched.image && !formik.errors.image,
              }
            )}
            readOnly
          />
          {formik.touched.image && formik.errors.image && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{formik.errors.image}</div>
            </div>
          )}
        </div>
      </div> */}

      {/* begin::Form group Firstname */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6 required'>Name</label>
        <input
          placeholder='Your company name'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('name')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.name && formik.errors.name,
            },
            {
              'is-valid': formik.touched.name && !formik.errors.name,
            }
          )}
        />
        {formik.touched.name && formik.errors.name && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.name}</span>
            </div>
          </div>
        )}
      </div>
      {/* begin::Form group Email */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6 required'>Email</label>
        <input
          placeholder='Your company email'
          type='email'
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6 required'>Phone</label>
        <input
          placeholder='Your company phone number'
          autoComplete='off'
          {...formik.getFieldProps('phone')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.phone && formik.errors.phone},
            {
              'is-valid': formik.touched.phone && !formik.errors.phone,
            }
          )}
        />
        {formik.touched.phone && formik.errors.phone && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.phone}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}
      {/* end::Form group */}
      <div className='fv-row mb-8'>
        {/* begin::Form group Lastname */}
        <label className='form-label fw-bolder text-dark fs-6 required'>Address</label>
        <textarea
          placeholder='Your company address'
          autoComplete='off'
          {...formik.getFieldProps('address')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.address && formik.errors.address,
            },
            {
              'is-valid': formik.touched.address && !formik.errors.address,
            }
          )}
        ></textarea>
        {formik.touched.address && formik.errors.address && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.address}</span>
            </div>
          </div>
        )}
        {/* end::Form group */}
      </div>

      <div className='fv-row mb-8'>
        <div className='form-label fw-bolder text-dark fs-6'>Tentukan Lokasi</div>
        <MapComponent center={center} pos={marker} setPos={setPos} />
      </div>

      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6 required'>Latitude</label>
        <input
          placeholder='Latitude'
          autoComplete='off'
          {...formik.getFieldProps('lat')}
          readOnly
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.lat && formik.errors.lat,
            },
            {
              'is-valid': formik.touched.lat && !formik.errors.lat,
            }
          )}
        />
        {formik.touched.lat && formik.errors.lat && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.lat}</span>
            </div>
          </div>
        )}
      </div>

      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6 required'>Longitude</label>
        <input
          placeholder='Longitude'
          autoComplete='off'
          {...formik.getFieldProps('lng')}
          readOnly
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.lng && formik.errors.lng,
            },
            {
              'is-valid': formik.touched.lng && !formik.errors.lng,
            }
          )}
        />
        {formik.touched.lng && formik.errors.lng && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.lng}</span>
            </div>
          </div>
        )}
      </div>
      {/* begin::Form group Password */}
      <div className='fv-row mb-8' data-kt-password-meter='true'>
        <div className='mb-1'>
          <label className='form-label fw-bolder text-dark fs-6 required'>Password</label>
          <div className='position-relative mb-3'>
            <div className='input-group mb-3'>
              <input
                type={pwdShow ? 'text' : 'password'}
                placeholder='Your Password'
                autoComplete='off'
                {...formik.getFieldProps('password')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid': formik.touched.password && formik.errors.password,
                  },
                  {
                    'is-valid': formik.touched.password && !formik.errors.password,
                  }
                )}
              />
              <div className='input-group-append'>
                <span
                  className='input-group-text border-0 rounded-0 cursor-pointer'
                  style={{padding: '1.12rem'}}
                  onClick={() => isPwdShow(() => !pwdShow)}
                >
                  <i className={`fa ${pwdShow ? 'fa-eye' : 'fa-eye-slash'} fs-3`}></i>
                </span>
              </div>
            </div>
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
          {/* begin::Meter */}
          <div
            className='d-flex align-items-center mb-3'
            data-kt-password-meter-control='highlight'
          >
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
          </div>
          {/* end::Meter */}
        </div>
        <div className='text-muted'>
          Use 8 or more characters with a mix of letters, numbers & symbols.
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      <div className='fv-row mb-5'>
        <label className='form-label fw-bolder text-dark fs-6 required'>Confirm Password</label>
        <div className='input-group mb-3'>
          <input
            type={confirmPwdShow ? 'text' : 'password'}
            placeholder='Password confirmation'
            autoComplete='off'
            {...formik.getFieldProps('changepassword')}
            className={clsx(
              'form-control bg-transparent',
              {
                'is-invalid': formik.touched.password && formik.errors.password,
              },
              {
                'is-valid': formik.touched.password && !formik.errors.password,
              }
            )}
          />
          <div className='input-group-append'>
            <span
              className='input-group-text border-0 rounded-0 cursor-pointer'
              style={{padding: '1.12rem'}}
              onClick={() => isConfirmPwdShow(() => !confirmPwdShow)}
            >
              <i className={`fa ${confirmPwdShow ? 'fa-eye' : 'fa-eye-slash'} fs-3`}></i>
            </span>
          </div>
        </div>

        {formik.touched.changepassword && formik.errors.changepassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.changepassword}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Submit</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        {/* <div className='text-gray-500 text-center fw-semibold fs-6'>
          Have an Account?{' '}
          <Link to='/auth' className='link-primary'>
            Sign in
          </Link>
        </div> */}
        {/* <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-lg btn-light-primary w-100 mb-5'
          >
            Cancel
          </button>
        </Link> */}
      </div>
      {/* end::Form group */}
    </form>
  )
}
