/* eslint-disable react/jsx-no-target-blank */
// import {KTIcon} from '../../../../helpers'
import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuMainCompany = () => {
  return (
    <>
      <SidebarMenuItem to='/dashboard' icon='home-3' title={'Home'} fontIcon='bi-app-indicator' />
      {/* <div className='menu-item'>
        <div className='menu-content pt-2 pb-1'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Setting</span>
        </div>
      </div>
      <SidebarMenuItem to='/setting' icon='setting-2' title='Setting' fontIcon='bi-layers' /> */}

      {/* <div className='menu-item'>
        <div className='menu-content pt-2 pb-1'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Tema & Sub Tema</span>
        </div>
      </div>

      <SidebarMenuItem to='/tema' icon='switch' title='Tema' fontIcon='bi-layers' />
      <SidebarMenuItem to='/subtema' icon='diamonds' title='Sub Tema' fontIcon='bi-layers' /> */}

      <div className='menu-item'>
        <div className='menu-content pt-2 pb-1'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Paket</span>
        </div>
      </div>
      <SidebarMenuItem to='/paket' icon='parcel' title='Paket' fontIcon='bi-layers' />
      <SidebarMenuItem to='/subscription' icon='bill' title='Subscription' fontIcon='bi-layers' />
      <SidebarMenuItem to='/machine' icon='technology-4' title='Machine' fontIcon='bi-layers' />
      <SidebarMenuItem
        to='/machine-order'
        icon='technology-2'
        title='Machine order'
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/machine-claim'
        icon='check-circle'
        title='Machine claim'
        fontIcon='bi-layers'
      />
      <div className='menu-item'>
        <div className='menu-content pt-2 pb-1'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Tema</span>
        </div>
      </div>
      <SidebarMenuItem to='/theme-collage' icon='educare' title='Collage' fontIcon='educare' />
      <SidebarMenuItem to='/theme-ai' icon='abstract-19' title='Ai theme' fontIcon='abstract-19' />
      <SidebarMenuItem to='/theme-emoji' icon='emoji-happy' title='Emoji' fontIcon='emoji-happy' />
      <SidebarMenuItem
        to='/theme-boomerang'
        icon='abstract-21'
        title='Boomerang'
        fontIcon='educare'
      />
      <SidebarMenuItem
        to='/theme-pas-photo'
        icon='abstract-14'
        title='Pas photo'
        fontIcon='abstract-14'
      />
      <SidebarMenuItem to='/theme-gif' icon='abstract-11' title='Gif' fontIcon='abstract-11' />
      <SidebarMenuItem
        to='/theme-sticker'
        icon='abstract-22'
        title='Sticker'
        fontIcon='abstract-22'
      />
      <SidebarMenuItem to='/theme-strip' icon='abstract-13' title='Strip' fontIcon='abstract-13' />
      <div className='menu-item'>
        <div className='menu-content pt-2 pb-1'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Platform</span>
        </div>
      </div>
      <SidebarMenuItem to='/withdraw' icon='soft-2' title='Withdraw' fontIcon='bi-layers' />
      <SidebarMenuItem to='/template' icon='abstract-29' title='Template' fontIcon='abstract-29' />
      {/* <SidebarMenuItem to='/builder' icon='switch' title='Layout Builder' fontIcon='bi-layers' /> */}

      {/* Company */}
      {/* <SidebarMenuItem to='/downline' icon='people' title='Downline' fontIcon='bi-layers' /> */}
      {/* <div className='menu-item'>
        <div className='menu-content pt-2 pb-1'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
            Kategori & Produk
          </span>
        </div>
      </div> */}
      {/* Tema */}

      {/* <div className='menu-item'>
        <div className='menu-content pt-2 pb-1'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>News & Campaign</span>
        </div>
      </div>
      <SidebarMenuItem to='/news' icon='note' title='News' fontIcon='bi-layers' />
      <SidebarMenuItem to='/campaign' icon='message-notif' title='Campaign' fontIcon='bi-layers' />
      <div className='menu-item'>
        <div className='menu-content pt-2 pb-1'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>List Rekening</span>
        </div>
      </div>
      <SidebarMenuItem to='/rekening' icon='note-2' title='Rekening' fontIcon='bi-layers' />

      <div className='menu-item'>
        <div className='menu-content pt-2 pb-1'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
            Transaksi & History
          </span>
        </div>
      </div>
      <SidebarMenuItemWithSub
        to='/transaksi'
        title='Transaksi'
        fontIcon='bi-archive'
        icon='element-plus'
      >
        <SidebarMenuItem to='/transaksi/topup' title='Transaksi Topup' hasBullet={true} />
        <SidebarMenuItem to='/transaksi/join' title='Transaksi Join' hasBullet={true} />
        <SidebarMenuItem to='/transaksi/withdraw' title='Transaksi Withdraw' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/hitory'
        title='History'
        fontIcon='bi-archive'
        icon='element-plus'
      >
        <SidebarMenuItem to='/history/topup' title='History Topup' hasBullet={true} />
        <SidebarMenuItem to='/history/join' title='History Join' hasBullet={true} />
        <SidebarMenuItem to='/history/withdraw' title='History Withdraw' hasBullet={true} />
      </SidebarMenuItemWithSub> */}
      {/*
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>
        </div>
      </div>
      <SidebarMenuItemWithSub
        to='/crafted/pages'
        title='Pages'
        fontIcon='bi-archive'
        icon='element-plus'
      >
        <SidebarMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <SidebarMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <SidebarMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <SidebarMenuItem
            to='/crafted/pages/profile/campaigns'
            title='Campaigns'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/documents'
            title='Documents'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>

        <SidebarMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <SidebarMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <SidebarMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </SidebarMenuItemWithSub>
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to='/crafted/accounts'
        title='Accounts'
        icon='profile-circle'
        fontIcon='bi-person'
      >
        <SidebarMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <SidebarMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub to='/error' title='Errors' fontIcon='bi-sticky' icon='cross-circle'>
        <SidebarMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <SidebarMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='element-7'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>
      <SidebarMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='message-text-2'
      >
        <SidebarMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItem
        to='/apps/user-management/users'
        icon='abstract-28'
        title='User management'
        fontIcon='bi-layers'
      /> */}
      {/* <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTIcon iconName='code' className='fs-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div> */}
    </>
  )
}

export {SidebarMenuMainCompany}
