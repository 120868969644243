/* eslint-disable jsx-a11y/anchor-is-valid */
// import clsx from 'clsx'
import {KTIcon} from '../../../../helpers'
import {useNavigate} from 'react-router-dom'
import {useState} from 'react'

type Props = {
  className: string
  description: string
  icon: boolean
  iconName: string | null | undefined
  iconColor: string
  stats: number | string
  labelColor: string
  textColor: string
  href: string
}

// const items: Array<{
//   name: string
//   initials?: string
//   src?: string
//   state?: string
// }> = [
//   {name: 'Alan Warden', initials: 'A', state: 'warning'},
//   {name: 'Michael Eberon', src: toAbsoluteUrl('/media/avatars/300-11.jpg')},
//   {name: 'Susan Redwood', initials: 'S', state: 'primary'},
//   {name: 'Melody Macy', src: toAbsoluteUrl('/media/avatars/300-2.jpg')},
//   {name: 'Perry Matthew', initials: 'P', state: 'danger'},
//   {name: 'Barry Walter', src: toAbsoluteUrl('/media/avatars/300-12.jpg')},
// ]

const CardsWidget7 = ({
  className,
  description,
  icon,
  iconName,
  iconColor = 'text-gray',
  stats,
  labelColor,
  textColor,
  href,
}: Props) => {
  const navigate = useNavigate()
  const [isHovered, setIsHovered] = useState(false)

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  const hoverStyle = 'bg-secondary'
  return (
    <div
      className={`card card-flush ${className} cursor-pointer ${isHovered && hoverStyle}`}
      onMouseEnter={() => handleMouseEnter()}
      onMouseLeave={() => handleMouseLeave()}
      onClick={() => {
        if (href !== "") {
          return navigate(href)
        }
      }}
    >
      <div className='card-header pt-5'>
        <div className='card-title d-flex flex-column'>
          <div className='card-title d-flex flex-column'>
            <span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2'>{stats}</span>
            <span className='text-gray-400 pt-1 fw-semibold fs-6'>Total {description}</span>
          </div>
        </div>
      </div>
      <div className='card-body d-flex flex-column justify-content-end pe-0'>
        {/* <span className='fs-6 fw-bolder text-gray-800 d-block mb-2'>{description}</span> */}
        <div className='symbol-group symbol-hover flex-nowrap'>
          {/* {items.map((item, index) => (
          <div
            className='symbol symbol-35px symbol-circle'
            data-bs-toggle='tooltip'
            title={item.name}
            key={`cw7-item-${index}`}
          >
            {item.src && <img alt='Pic' src={item.src} />}
            {item.state && item.initials && (
              <span
                className={clsx(
                  'symbol-label fw-bold',
                  'bg-' + item.state,
                  'text-inverse-' + item.state
                )}
              >
                {item.initials}
              </span>
            )}
          </div>
        ))} */}
          <KTIcon
            iconName={iconName ? iconName : ''}
            iconType='duotone'
            className={`fs-4x border border-secondary rounded-circle p-3 ${iconColor}`}
          />

          {/* <a href='#' className='symbol symbol-35px symbol-circle'>
          <span
            className={clsx('symbol-label fs-8 fw-bold', 'bg-' + labelColor, 'text-' + textColor)}
          >
            +42
          </span>
        </a> */}
        </div>
      </div>
    </div>
  )
}
export {CardsWidget7}
