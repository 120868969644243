import Swal from 'sweetalert2'
type EnumIcon = string | 'success' | 'info' | 'warning' | 'danger' | 'question' | any
type ToastTitle = string | null
type ToastTimer = number | 1000 | 1500 | 2000 | null
type ParamsToast = {
  icon: EnumIcon
  title?: ToastTitle
  html?: string
  timer?: ToastTimer
}
export const Toaster = (params: ParamsToast) => {
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    customClass: {
      popup: 'colored-toast',
    },
    showConfirmButton: false,
    timer: params.timer || 1500,
    timerProgressBar: true,
    showCloseButton: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    },
  })

  return Toast.fire({
    icon: params.icon,
    title: params.title || '',
    html: params.html ? `<span style="color:#363636">${params.html}</span>` : '',
  })
}
