import useFetch from '@av-hooks/useFetch'
import {useAuth} from '@av-modules/auth'
import dayjs from 'dayjs'
import {useMemo} from 'react'
import {AnalyticsAccounting, AnalyticsCompany, AnalyticsMachine} from '../models/_model'

const useFetchDataDashboard = (date: any, activeMachine: any) => {
  const {auth} = useAuth()
  const isAdmin = auth?.data?.user.role === 'SUPER_ADMIN'

  const {items: mapsItem, isLoading: mapsItemIsloading} = useFetch({
    path: 'dasboard/maps',
    invalidateKey: 'dasboard/maps_none',
    query: {
      start_at: date.map.start_at || dayjs().startOf('week').toISOString(),
      end_at: date.map.end_at || dayjs().endOf('week').toISOString(),
    },
  })
  const {items: packetItem} = useFetch({
    path: 'dasboard/packet',
    invalidateKey: 'dasboard/packet_none',
    query: {
      start_at: date.packet.start_at || dayjs().startOf('week').toISOString(),
      end_at: date.packet.end_at || dayjs().endOf('week').toISOString(),
    },
    enabled: isAdmin,
  })
  const {items: productItem} = useFetch({
    path: 'dasboard/product',
    invalidateKey: 'dasboard/product_none',
    query: {
      start_at: date.product.start_at || dayjs().startOf('week').toISOString(),
      end_at: date.product.end_at || dayjs().endOf('week').toISOString(),
    },
    enabled: isAdmin,
  })
  const {items: companyItem} = useFetch({
    path: 'dasboard/company',
    invalidateKey: 'dasboard/company_none',
    query: {
      start_at: date.company.start_at || dayjs().startOf('week').toISOString(),
      end_at: date.company.end_at || dayjs().endOf('week').toISOString(),
    },
    enabled: isAdmin,
  })
  const {items: themeItem} = useFetch({
    path: 'dasboard/theme',
    invalidateKey: 'dasboard/theme_none',
    query: {
      start_at: date.theme.start_at || dayjs().startOf('week').toISOString(),
      end_at: date.theme.end_at || dayjs().endOf('week').toISOString(),
    },
    enabled: isAdmin,
  })
  const {items: accountingItem} = useFetch({
    path: 'dasboard/accounting',
    invalidateKey: 'dasboard/accounting_none',
    query: {
      start_at: date.accounting.start_at || dayjs().startOf('week').toISOString(),
      end_at: date.accounting.end_at || dayjs().endOf('week').toISOString(),
    },
  })
  const {items: machineItem} = useFetch({
    path: 'dasboard/machine',
    invalidateKey: 'dasboard/machine_none',
    query: {
      start_at: date.machine.start_at || dayjs().startOf('week').toISOString(),
      end_at: date.machine.end_at || dayjs().endOf('week').toISOString(),
    },
  })
  const {items: orderProduct} = useFetch({
    path: 'company/dashboard/order/product',
    invalidateKey: 'company/dashboard/order/product_none',
    query: {
      machine_id: activeMachine?.value ?? null,
      start_at: date?.chart_company?.start_at ? dayjs(date.chart_company.start_at).format('YYYY-MM-DD') : dayjs().startOf('day').format('YYYY-MM-DD'),
      end_at: date?.chart_company?.end_at ? dayjs(date.chart_company.end_at).format('YYYY-MM-DD') : dayjs().endOf('day').add(1, 'day').format('YYYY-MM-DD'),
    },
    enabled: (Boolean(auth?.data?.user?.role === 'COMPANY') && (date?.chart_company?.start_at !== null && date?.chart_company?.end_at !== null)) || (Boolean(auth?.data?.user?.role === 'COMPANY') && (date?.chart_company?.start_at === null && date?.chart_company?.end_at === null)),
  })
  const {items: paymentSource} = useFetch({
    path: 'company/dashboard/order/payment_source',
    invalidateKey: 'company/dashboard/order/payment_source_none',
    query: {
      machine_id: activeMachine?.value ?? null,
      start_at: date?.chart_company?.start_at ? dayjs(date.chart_company.start_at).format('YYYY-MM-DD') : dayjs().startOf('day').format('YYYY-MM-DD'),
      end_at: date?.chart_company?.end_at ? dayjs(date.chart_company.end_at).format('YYYY-MM-DD') : dayjs().endOf('day').add(1, 'day').format('YYYY-MM-DD'),
    },
    enabled: (Boolean(auth?.data?.user?.role === 'COMPANY') && (date?.chart_company?.start_at !== null && date?.chart_company?.end_at !== null)) || (Boolean(auth?.data?.user?.role === 'COMPANY') && (date?.chart_company?.start_at === null && date?.chart_company?.end_at === null)),
  })
  const {items: order} = useFetch({
    path: 'company/dashboard/order',
    invalidateKey: 'company/dashboard/order_none',
    query: {
      machine_id: activeMachine?.value ?? null,
      start_at: date?.chart_company?.start_at ? dayjs(date.chart_company.start_at).format('YYYY-MM-DD') : dayjs().startOf('day').format('YYYY-MM-DD'),
      end_at: date?.chart_company?.end_at ? dayjs(date.chart_company.end_at).format('YYYY-MM-DD') : dayjs().endOf('day').add(1, 'day').format('YYYY-MM-DD'),
    },
    enabled: (Boolean(auth?.data?.user?.role === 'COMPANY') && (date?.chart_company?.start_at !== null && date?.chart_company?.end_at !== null)) || (Boolean(auth?.data?.user?.role === 'COMPANY') && (date?.chart_company?.start_at === null && date?.chart_company?.end_at === null)),
  })
  const {items: claim} = useFetch({
    path: 'company/dashboard/claim',
    invalidateKey: 'company/dashboard/claim_none',
    query: {
      machine_id: activeMachine?.value ?? null,
      start_at: date?.chart_company?.start_at ? dayjs(date.chart_company.start_at).format('YYYY-MM-DD') : dayjs().startOf('day').format('YYYY-MM-DD'),
      end_at: date?.chart_company?.end_at ? dayjs(date.chart_company.end_at).format('YYYY-MM-DD') : dayjs().endOf('day').add(1, 'day').format('YYYY-MM-DD'),
    },
    enabled: (Boolean(auth?.data?.user?.role === 'COMPANY') && (date?.chart_company?.start_at !== null && date?.chart_company?.end_at !== null)) || (Boolean(auth?.data?.user?.role === 'COMPANY') && (date?.chart_company?.start_at === null && date?.chart_company?.end_at === null)),
  })
  const {items: refund} = useFetch({
    path: 'company/dashboard/refund',
    invalidateKey: 'company/dashboard/refund_none',
    query: {
      machine_id: activeMachine?.value ?? null,
      start_at: date?.chart_company?.start_at ? dayjs(date.chart_company.start_at).format('YYYY-MM-DD') : dayjs().startOf('day').format('YYYY-MM-DD'),
      end_at: date?.chart_company?.end_at ? dayjs(date.chart_company.end_at).format('YYYY-MM-DD') : dayjs().endOf('day').add(1, 'day').format('YYYY-MM-DD'),
    },
    enabled: (Boolean(auth?.data?.user?.role === 'COMPANY') && (date?.chart_company?.start_at !== null && date?.chart_company?.end_at !== null)) || (Boolean(auth?.data?.user?.role === 'COMPANY') && (date?.chart_company?.start_at === null && date?.chart_company?.end_at === null)),
  })
  const {items: voucher} = useFetch({
    path: 'company/dashboard/voucher',
    invalidateKey: 'company/dashboard/voucher_none',
    query: {
      machine_id: activeMachine?.value ?? null,
      start_at: date?.chart_company?.start_at ? dayjs(date.chart_company.start_at).format('YYYY-MM-DD') : dayjs().startOf('day').format('YYYY-MM-DD'),
      end_at: date?.chart_company?.end_at ? dayjs(date.chart_company.end_at).format('YYYY-MM-DD') : dayjs().endOf('day').add(1, 'day').format('YYYY-MM-DD'),
    },
    enabled: (Boolean(auth?.data?.user?.role === 'COMPANY') && (date?.chart_company?.start_at !== null && date?.chart_company?.end_at !== null)) || (Boolean(auth?.data?.user?.role === 'COMPANY') && (date?.chart_company?.start_at === null && date?.chart_company?.end_at === null)),
  })
  const {items: listMachine} = useFetch({
    path: 'company/machine',
    invalidateKey: 'company/machine_none',
    query: {
      page: 1,
      size: 1000
    },
    enabled: Boolean(auth?.data?.user?.role === 'COMPANY'),
  })

  const dataRebuild = useMemo(() => {
    return {
      paymentSource: paymentSource?.data,
      orderProduct: orderProduct?.data,
      order: order?.data,
      claim: claim?.data,
      refund: refund?.data,
      voucher: voucher?.data,
      maps: mapsItem?.data,
      listMachine: listMachine?.data,
      company: companyItem?.data as AnalyticsCompany,
      machine: machineItem?.data as AnalyticsMachine,
      accounting: accountingItem?.data as AnalyticsAccounting,
      packet: [
        {
          name: 'Total Active',
          value: packetItem?.data?.total_active,
        },
        {
          name: 'Total Inactive',
          value: packetItem?.data?.total_inactive,
        },
        {
          name: 'Total Solt Amount',
          value: packetItem?.data?.total_sold_amount,
        },
        {
          name: 'Total Sold Count',
          value: packetItem?.data?.total_sold_count,
        },
      ],
      product: [
        {
          name: 'Total Active',
          value: productItem?.data?.total_active,
        },
        {
          name: 'Total Inactive',
          value: productItem?.data?.total_inactive,
        },
      ],
      theme: [
        {
          name: 'Total ai',
          value: themeItem?.data?.total_ai,
        },
        {
          name: 'Total boomerang',
          value: themeItem?.data?.total_boomerang,
        },
        {
          name: 'Total collage',
          value: themeItem?.data?.total_collage,
        },
        {
          name: 'Total gif',
          value: themeItem?.data?.total_gif,
        },
        {
          name: 'Total pas photo',
          value: themeItem?.data?.total_pas_photo,
        },
        {
          name: 'Total strip',
          value: themeItem?.data?.total_strip,
        },
      ],
    }
  }, [
    packetItem,
    productItem,
    mapsItem,
    companyItem,
    machineItem,
    themeItem,
    accountingItem,
    paymentSource,
    orderProduct,
    order,
    claim,
    refund,
    voucher,
    listMachine
  ])

  return {dataRebuild, mapsItemIsloading, isAdmin}
}

export default useFetchDataDashboard
