import React, {useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
const URL_LP = process.env.REACT_APP_LANDING_PAGE_URL || 'https://photobooth-id.framer.website/'
function LandingPage() {
  const navigate = useNavigate()
  useEffect(() => {
    window.open(URL_LP, '_blank')
    navigate('/')
  }, [])
  return <div></div>
}

export {LandingPage}
