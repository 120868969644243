import React, {useState} from 'react'
import {GoogleMap, Marker, useJsApiLoader, OverlayView} from '@react-google-maps/api'
import styled from 'styled-components'
import { G_MAPS } from '../models/_model'

const containerStyle = {
  width: '100%',
  height: '700px',
}

type PropsType = {
  data: G_MAPS
  isLoading: boolean
}

type Pos = {
  lat: number
  lng: number
}
const Typography = styled.p`
  min-width: 200px;
  line-height: 16px;
  margin-top: 6px;
  font-size: 1.9em;
`
function GoogleMaps(props: PropsType) {
  const {isLoading, data} = props
  const [zoom, setZoom] = useState<number>(1)
  const {isLoaded} = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_API_KEY_MAPS || '',
  })
  const [center, setCenter] = useState<Pos>({
    lat: -0.27694888611336194,
    lng: 118.8093467106876,
  })

  const [map, setMap] = React.useState<any>(null)

  const onLoad = React.useCallback(function callback(map: {
    fitBounds: (arg0: google.maps.LatLngBounds) => void
  }) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(center)
    map.fitBounds(bounds)
    setMap(map)
    setTimeout(() => {
      setZoom(4.5)
    }, 1000)
  },
  [])
  const onUnmount = React.useCallback(function callback(map: any) {
    setMap(null)
  }, [])
  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={zoom}
      onLoad={onLoad}
      onUnmount={onUnmount}
      options={{
        streetViewControl: false,
        mapTypeControl: false,
      }}
    >
      {isLoading ? (
        <div>Loading</div>
      ) : (
        data?.locations?.map((item, index) => (
          <OverlayView
            key={index}
            position={{
              lat: item.lat || 1,
              lng: item.lng || 1,
            }}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          >
            <div className='d-flex justify-content-center'>
              <Marker
                position={{
                  lat: item.lat || 1,
                  lng: item.lng || 1,
                }}
              ></Marker>
              <Typography className='text-white text-center'>{item.name}</Typography>
            </div>
          </OverlayView>
        ))
      )}
    </GoogleMap>
  ) : (
    <></>
  )
}

export default React.memo(GoogleMaps)
