import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
// import UserPage from '../pages/users/UserPage'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const CompanyPage = lazy(() => import('../pages/company/UserPage'))
  const PacketAdminPage = lazy(() => import('../pages/packet-admin/PacketAdminPage'))
  const PacketPage = lazy(() => import('../pages/packet/PacketPage'))
  const SubscriptionPage = lazy(() => import('../pages/subscription/SubscriptionPage'))
  const SubscriptionAdminPage = lazy(() => import('../pages/subscription-admin/SubscriptionPage'))
  const NotificationPage = lazy(() => import('../pages/notification/NotificationPage'))
  const MachineAdminPage = lazy(() => import('../pages/machine-admin/MachineAdminPage'))
  const MachinePage = lazy(() => import('../pages/machine/MachinePage'))
  const BoomerangPage = lazy(() => import('../pages/theme/boomerang/BoomerangPage'))
  const ThirdPartyMediaPage = lazy(() => import('../pages/setting/ThirdPartyPage'))
  const ThemeCategoryPage = lazy(() => import('../pages/tema-kategori/ThemeCategoryPage'))
  const TransactionPage = lazy(() => import('../pages/transaction/TransactionPage'))
  const OrderPage = lazy(() => import('../pages/order/OrderPage'))
  const OrderPacketPage = lazy(() => import('../pages/order-packet/OrderPacket'))
  const TemplatePage = lazy(() => import('../pages/template/TemplatePage'))
  const TemplateAdminPage = lazy(() => import('../pages/template-admin/TemplateAdminPage'))
  const WithdrawAdminPage = lazy(() => import('../pages/withdraw-admin/WithdrawPageAdmin'))
  const WithdrawPage = lazy(() => import('../pages/withdraw/WithdrawPage'))
  const MachineOrderPage = lazy(() => import('../pages/machine-order/MachineOrderPage'))
  const WithdrawApprovalAdminPage = lazy(
    () => import('../pages/withdraw-approval-admin/WithdrawApprovalPageAdmin')
  )
  /// THEME ADMIN
  const CollageThemeAdminPage = lazy(() => import('../pages/theme-admin/collage/CollageAdminPage'))
  const AiThemeAdminPage = lazy(() => import('../pages/theme-admin/ai/AiThemePageAdmin'))
  const AdminBoomerangPage = lazy(() => import('../pages/theme-admin/boomerang/AdminBoomerangPage'))
  const EmojiThemeAdminPage = lazy(() => import('../pages/theme-admin/emoji/EmojiAdminPage'))
  const PasPhotoThemeAdminPage = lazy(
    () => import('../pages/theme-admin/pasPhoto/PasPhotoAdminPage')
  )
  const GifThemeAdminPage = lazy(() => import('../pages/theme-admin/gif/GifAdminPage'))
  const StripThemeAdminPage = lazy(() => import('../pages/theme-admin/strip/StripAdminPage'))
  const StickerThemeAdminPage = lazy(() => import('../pages/theme-admin/sticker/StickerAdminPage'))
  ///
  /// THEME COMPANY
  const CollageThemePage = lazy(() => import('../pages/theme/collage/CollagePage'))
  const AiThemePage = lazy(() => import('../pages/theme/ai/AiPage'))
  const EmojiThemePage = lazy(() => import('../pages/theme/emoji/EmojiPage'))
  const PasPhotoThemePage = lazy(() => import('../pages/theme/pasPhoto/PasPhoto'))
  const GifThemePage = lazy(() => import('../pages/theme/gif/GifPage'))
  const StripThemePage = lazy(() => import('../pages/theme/strip/StripPage'))
  const StickerThemePage = lazy(() => import('../pages/theme/sticker/StickerPage'))
  ///
  // Unused page
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const UserPage = lazy(() => import('../pages/users/UserPage'))
  const ProductPage = lazy(() => import('../pages/product/ProductPage'))
  const CategoryPage = lazy(() => import('../pages/category/CategoryPage'))
  const NewsPage = lazy(() => import('../pages/news/NewsPage'))
  const CampaignPage = lazy(() => import('../pages/campaign/CampaignPage'))
  const RekeningPage = lazy(() => import('../pages/rekening/RekeningPage'))
  const TransaksiTopupPage = lazy(() => import('../pages/transaksi/topup/TransaksiTopupPage'))
  const TransaksiWithdrawPage = lazy(
    () => import('../pages/transaksi/withdraw/TransaksiWithdrawPage')
  )

  const TransaksiJoinPage = lazy(() => import('../pages/transaksi/join/TransaksiJoinPage'))
  const HistoryTopupPage = lazy(() => import('../pages/history/topup/HistoryTopupPage'))
  const HistoryJoinPage = lazy(() => import('../pages/history/join/HistoryJoinPage'))
  const HistoryWithdrawPage = lazy(() => import('../pages/history/withdraw/HistoryWithdrawPage'))
  const DownlinePage = lazy(() => import('../pages/downline/DownlinePage'))
  const VoucherPage = lazy(() => import('../pages/voucher/VoucherPage'))
  const MachineClaimPage = lazy(() => import('../pages/machine-claim/MachineClaimPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        <Route
          path='company'
          element={
            <SuspensedView>
              <CompanyPage />
            </SuspensedView>
          }
        />
        <Route
          path='paket/admin'
          element={
            <SuspensedView>
              <PacketAdminPage />
            </SuspensedView>
          }
        />
        <Route
          path='template/admin'
          element={
            <SuspensedView>
              <TemplateAdminPage />
            </SuspensedView>
          }
        />
        <Route
          path='withdraw/admin'
          element={
            <SuspensedView>
              <WithdrawAdminPage />
            </SuspensedView>
          }
        />
        <Route
          path='withdraw'
          element={
            <SuspensedView>
              <WithdrawPage />
            </SuspensedView>
          }
        />
        <Route
          path='machine-order'
          element={
            <SuspensedView>
              <MachineOrderPage />
            </SuspensedView>
          }
        />
        <Route
          path='withdraw-approval/admin'
          element={
            <SuspensedView>
              <WithdrawApprovalAdminPage />
            </SuspensedView>
          }
        />
        <Route
          path='template'
          element={
            <SuspensedView>
              <TemplatePage />
            </SuspensedView>
          }
        />
        <Route
          path='paket'
          element={
            <SuspensedView>
              <PacketPage />
            </SuspensedView>
          }
        />
        <Route
          path='subscription'
          element={
            <SuspensedView>
              <SubscriptionPage />
            </SuspensedView>
          }
        />
        <Route
          path='notification'
          element={
            <SuspensedView>
              <NotificationPage />
            </SuspensedView>
          }
        />
        <Route
          path='machine/admin'
          element={
            <SuspensedView>
              <MachineAdminPage />
            </SuspensedView>
          }
        />
        <Route
          path='machine'
          element={
            <SuspensedView>
              <MachinePage />
            </SuspensedView>
          }
        />

        <Route
          path='subscription/admin'
          element={
            <SuspensedView>
              <SubscriptionAdminPage />
            </SuspensedView>
          }
        />

        <Route
          path='voucher'
          element={
            <SuspensedView>
              <VoucherPage />
            </SuspensedView>
          }
        />
        <Route
          path='machine-claim'
          element={
            <SuspensedView>
              <MachineClaimPage />
            </SuspensedView>
          }
        />
        {/* /// THEME ADMIN */}
        <Route
          path='theme-collage/admin'
          element={
            <SuspensedView>
              <CollageThemeAdminPage />
            </SuspensedView>
          }
        />
        <Route
          path='theme-ai/admin'
          element={
            <SuspensedView>
              <AiThemeAdminPage />
            </SuspensedView>
          }
        />
        <Route
          path='theme-emoji/admin'
          element={
            <SuspensedView>
              <EmojiThemeAdminPage />
            </SuspensedView>
          }
        />
        <Route
          path='theme-boomerang/admin'
          element={
            <SuspensedView>
              <AdminBoomerangPage />
            </SuspensedView>
          }
        />
        <Route
          path='theme-pas-photo/admin'
          element={
            <SuspensedView>
              <PasPhotoThemeAdminPage />
            </SuspensedView>
          }
        />
        <Route
          path='theme-gif/admin'
          element={
            <SuspensedView>
              <GifThemeAdminPage />
            </SuspensedView>
          }
        />
        <Route
          path='theme-strip/admin'
          element={
            <SuspensedView>
              <StripThemeAdminPage />
            </SuspensedView>
          }
        />
        <Route
          path='theme-sticker/admin'
          element={
            <SuspensedView>
              <StickerThemeAdminPage />
            </SuspensedView>
          }
        />
        {/* /// */}
        {/* /// THEME COMPANY */}
        <Route
          path='theme-collage'
          element={
            <SuspensedView>
              <CollageThemePage />
            </SuspensedView>
          }
        />
        <Route
          path='theme-ai'
          element={
            <SuspensedView>
              <AiThemePage />
            </SuspensedView>
          }
        />
        <Route
          path='/theme-emoji'
          element={
            <SuspensedView>
              <EmojiThemePage />
            </SuspensedView>
          }
        />
        <Route
          path='theme-boomerang'
          element={
            <SuspensedView>
              <BoomerangPage />
            </SuspensedView>
          }
        />
        <Route
          path='theme-pas-photo'
          element={
            <SuspensedView>
              <PasPhotoThemePage />
            </SuspensedView>
          }
        />
        <Route
          path='theme-gif'
          element={
            <SuspensedView>
              <GifThemePage />
            </SuspensedView>
          }
        />
        <Route
          path='theme-strip'
          element={
            <SuspensedView>
              <StripThemePage />
            </SuspensedView>
          }
        />
        <Route
          path='theme-sticker'
          element={
            <SuspensedView>
              <StickerThemePage />
            </SuspensedView>
          }
        />
        {/* /// */}
        <Route
          path='third-party-media'
          element={
            <SuspensedView>
              <ThirdPartyMediaPage />
            </SuspensedView>
          }
        />
        <Route
          path='kategori-tema'
          element={
            <SuspensedView>
              <ThemeCategoryPage />
            </SuspensedView>
          }
        />
        <Route
          path='transaction'
          element={
            <SuspensedView>
              <TransactionPage />
            </SuspensedView>
          }
        />
        <Route
          path='order'
          element={
            <SuspensedView>
              <OrderPage />
            </SuspensedView>
          }
        />
        {/*  */}
        <Route
          path='product'
          element={
            <SuspensedView>
              <ProductPage />
            </SuspensedView>
          }
        />
        <Route
          path='kategori'
          element={
            <SuspensedView>
              <CategoryPage />
            </SuspensedView>
          }
        />
        <Route
          path='news'
          element={
            <SuspensedView>
              <NewsPage />
            </SuspensedView>
          }
        />
        <Route
          path='campaign'
          element={
            <SuspensedView>
              <CampaignPage />
            </SuspensedView>
          }
        />
        <Route
          path='rekening'
          element={
            <SuspensedView>
              <RekeningPage />
            </SuspensedView>
          }
        />
        <Route
          path='/transaksi/topup'
          element={
            <SuspensedView>
              <TransaksiTopupPage />
            </SuspensedView>
          }
        />
        <Route
          path='/transaksi/withdraw'
          element={
            <SuspensedView>
              <TransaksiWithdrawPage />
            </SuspensedView>
          }
        />
        <Route
          path='/transaksi/join'
          element={
            <SuspensedView>
              <TransaksiJoinPage />
            </SuspensedView>
          }
        />
        <Route
          path='/history/topup'
          element={
            <SuspensedView>
              <HistoryTopupPage />
            </SuspensedView>
          }
        />
        <Route
          path='/history/join'
          element={
            <SuspensedView>
              <HistoryJoinPage />
            </SuspensedView>
          }
        />
        <Route
          path='/history/withdraw'
          element={
            <SuspensedView>
              <HistoryWithdrawPage />
            </SuspensedView>
          }
        />
        <Route
          path='/downline'
          element={
            <SuspensedView>
              <DownlinePage />
            </SuspensedView>
          }
        />
        {/* Lazy Modules */}
        <Route
          path='pengguna/*'
          element={
            <SuspensedView>
              <UserPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        {/* <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        /> */}
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
      <Route
        path='order-packet/:id'
        element={
          <SuspensedView>
            <OrderPacketPage />
          </SuspensedView>
        }
      />
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
