import dayjs from 'dayjs'
import React from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import './WeekPicker.css' // CSS untuk hover effect

const InputDatePicker = ({
  label,
  type,
  onChange,
  value,
  from,
}: {
  label: string
  type: 'year' | 'month' | 'week' | 'normal'
  onChange: any
  value: Date | null
  from: 'start' | 'end'
}) => {
  const handleDateChange = (date: any) => {
    let updatedDate

    if (type === 'week') {
      // Menentukan tanggal awal atau akhir minggu
      const startOfWeek = dayjs(date).startOf('week').toDate() // Awal minggu
      const endOfWeek = dayjs(date).endOf('week').toDate() // Akhir minggu
      updatedDate = from === 'start' ? startOfWeek : endOfWeek
    } else if (type === 'month') {
      // Menentukan tanggal awal atau akhir bulan
      const startOfMonth = dayjs(date).startOf('month').toDate() // Awal bulan
      const endOfMonth = dayjs(date).endOf('month').toDate() // Akhir bulan
      updatedDate = from === 'start' ? startOfMonth : endOfMonth
    } else if (type === 'year') {
      // Menentukan tanggal awal atau akhir tahun
      const startOfYear = dayjs(date).startOf('year').toDate() // Awal tahun
      const endOfYear = dayjs(date).endOf('year').toDate() // Akhir tahun
      updatedDate = from === 'start' ? startOfYear : endOfYear
    } else {
      // Jika tipe normal, langsung update tanggal
      updatedDate = date
    }

    onChange(updatedDate)
  }

  return (
    <div className='flex-column d-flex w-100'>
      <label className='required fw-bold fs-6 text-nowrap mb-3'>{label}</label>
      {type === 'year' && (
        <div className='input-group d-flex col-12 p-0'>
          <div className='flex-grow-1 customDatePickerWidth'>
            <DatePicker
              isClearable
              selected={value}
              onChange={handleDateChange} // Menggunakan handleDateChange
              dateFormat='yyyy'
              showYearPicker
              className='form-control form-control-sm mb-lg-0 rounded-end-0'
              placeholderText='Select Year'
            />
          </div>
          <span className='input-group-text p-0 d-flex align-items-center px-4'>
            <i className='bi bi-calendar3'></i>
          </span>
        </div>
      )}
      {type === 'month' && (
        <div className='input-group d-flex col-12 p-0'>
          <div className='flex-grow-1 customDatePickerWidth'>
            <DatePicker
              isClearable
              selected={value}
              onChange={handleDateChange} // Menggunakan handleDateChange
              dateFormat='MM/yyyy'
              showMonthYearPicker
              className='form-control form-control-sm mb-lg-0 rounded-end-0'
              placeholderText='Select Month'
            />
          </div>
          <span className='input-group-text p-0 d-flex align-items-center px-4'>
            <i className='bi bi-calendar3'></i>
          </span>
        </div>
      )}
      {type === 'week' && (
        <div className='input-group d-flex col-12 p-0'>
          <div className='flex-grow-1 customDatePickerWidth'>
            <DatePicker
              isClearable
              selected={value}
              onChange={handleDateChange} // Menggunakan handleDateChange
              placeholderText='Select Week'
              className='form-control form-control-sm mb-lg-0 rounded-end-0'
              calendarClassName='week-picker'
              showPopperArrow={false}
              dateFormat='I/R'
              locale='en-GB'
              showWeekNumbers
              showWeekPicker
            />
          </div>
          <span className='input-group-text p-0 d-flex align-items-center px-4 week-picker'>
            <i className='bi bi-calendar3'></i>
          </span>
        </div>
      )}
      {type === 'normal' && (
        <div className='input-group d-flex col-12 p-0'>
          <div className='flex-grow-1 customDatePickerWidth'>
            <DatePicker
              isClearable
              selected={value}
              onChange={onChange}
              className='form-control form-control-sm mb-lg-0 rounded-end-0'
              placeholderText='Select Date'
              dateFormat='dd/MM/yyyy'
              wrapperClassName='w-100'
            />
          </div>
          <span className='input-group-text p-0 d-flex align-items-center px-4'>
            <i className='bi bi-calendar3'></i>
          </span>
        </div>
      )}
    </div>
  )
}

export default InputDatePicker
