// src/redux/categoriesSlice.ts
import {createSlice, createAsyncThunk, createSelector} from '@reduxjs/toolkit'
import axios from 'axios'

interface Category {
  slug: string
  name: string
  url: string
}

interface CategoriesState {
  categories: Category[]
  loading: boolean
  error: string | null
}

const initialState: CategoriesState = {
  categories: [],
  loading: false,
  error: null,
}

export const fetchCategories = createAsyncThunk('categories/fetchCategories', async () => {
  const response = await axios.get('https://dummyjson.com/products/categories')
  return response.data
})

const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategories.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.loading = false
        state.categories = action.payload
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message || 'Failed to fetch categories'
      })
  },
})

export default categoriesSlice.reducer

export const selectCounterValue = (state: any) => state.counter.value

export const selectCounterLogs = (state: any) => state.counter.logs

export const selectCounterStatus = createSelector(
  [selectCounterValue, selectCounterLogs],
  (value, logs) => ({
    value,
    logsCount: logs.length,
  })
)
