import clsx from 'clsx'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {HeaderNotificationsMenu, HeaderUserMenu, Search, ThemeModeSwitcher} from '../../../partials'
import {useAuth} from '../../../../app/modules/auth'
import {useEffect, useMemo, useState} from 'react'
import {useLocation} from 'react-router-dom'
import useFetch from '../../../../hooks/useFetch'
import {NOTIFICATION_COMPANY} from '../../../partials/layout/header-menus/_model'
// import {useLayout} from '../../core'

const itemClass = 'ms-1 ms-lg-3'
const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px'
const userAvatarClass = 'symbol-35px symbol-md-40px'
const btnIconClass = 'fs-1'

const Navbar = () => {
  const {auth} = useAuth()
  const [data, setData] = useState<NOTIFICATION_COMPANY[]>([])
  const [page, setPage] = useState<any>(1)
  // const {config} = useLayout()
  const {currentUser} = useAuth()
  const location = useLocation()
  const {
    items: notificationCount,
    isLoading: notificationCountIsLoading,
    refetch: notificationCountRefetch,
  } = useFetch({
    path: 'company/notification/unread',
    invalidateKey: 'company/notification/unread',
    enabled: Boolean(auth?.data?.user?.role === 'COMPANY'),
  })
  const {
    items: itemsNotification,
    isLoading: isLoadingNotification,
    refetch: refetchDataNotification,
  } = useFetch({
    path: 'company/notification',
    invalidateKey: 'company/notification_none',
    query: {
      page,
      size: 5,
    },
    enabled: Boolean(page > 1),
  })
  const handleClickNotification = () => {
    refetchDataNotification()
  }
  useEffect(() => {
    setData((prev: any) => {
      const newValue = [...prev].concat(itemsNotification?.data?.items || [])
      return newValue
    })
  }, [page, itemsNotification?.data?.items])

  const unReadNotif = useMemo(() => {
    return notificationCount?.data?.unread_count > 99
      ? '99+'
      : notificationCount?.data?.unread_count
  }, [notificationCount])
  useEffect(() => {
    if (Boolean(auth?.data?.user?.role === 'COMPANY')) {
      notificationCountRefetch()
    }
  }, [location.pathname])
  return (
    <div className='app-navbar flex-shrink-0'>
      {/* <div className={clsx('app-navbar-item align-items-stretch', itemClass)}>
        <Search />
      </div> */}

      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <div id='kt_activities_toggle' className={btnClass}>
          <KTIcon iconName='chart-simple' className={btnIconClass} />
        </div>
      </div> */}
      {auth?.data?.user?.role === 'COMPANY' && (
        <>
          <div className={clsx('app-navbar-item', itemClass)}>
            <div
              data-kt-menu-trigger="{default: 'click'}"
              data-kt-menu-attach='parent'
              data-kt-menu-placement='bottom-end'
              className={`${btnClass} mr-0`}
              onClick={handleClickNotification}
            >
              <KTIcon iconName='notification-on' className={btnIconClass} />
            </div>
            <HeaderNotificationsMenu
              isLoading={isLoadingNotification}
              data={data}
              items={itemsNotification}
              page={page}
              setPage={setPage}
            />
            {unReadNotif !== 0 && !notificationCountIsLoading && (
              <div className='text-white bg-danger h-30px w-30px rounded-circle d-flex justify-content-center align-items-center'>
                {unReadNotif}
              </div>
            )}
          </div>
        </>
      )}

      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <div className={clsx('position-relative', btnClass)} id='kt_drawer_chat_toggle'>
          <KTIcon iconName='message-text-2' className={btnIconClass} />
          <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink' />
        </div>
      </div> */}

      <div className={clsx('app-navbar-item', itemClass)}>
        <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <img
            src={
              currentUser?.user.image
                ? `${process.env.REACT_APP_STORAGE_URL}/images/${currentUser?.user.image}`
                : toAbsoluteUrl('/media/avatars/blank.png')
            }
            onError={(e: any) => {
              e.target.onerror = null
              e.currentTarget.src = toAbsoluteUrl('/media/avatars/blank.png')
            }}
            alt=''
          />
        </div>
        <HeaderUserMenu />
      </div>

      {/* {config.app?.header?.default?.menu?.display && (
        <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-35px h-35px'
            id='kt_app_header_menu_toggle'
          >
            <KTIcon iconName='text-align-left' className={btnIconClass} />
          </div>
        </div>
      )} */}
    </div>
  )
}

export {Navbar}
