import {useQuery} from '@tanstack/react-query'
import qs from 'qs'
import axios from 'axios'

function useFetch({
  invalidateKey,
  path,
  enabled = true,
  query,
  retry = 3,
}: {
  invalidateKey: string
  path: string
  enabled?: boolean
  query?: any
  retry?: any
}) {
  const queryString = qs.stringify(query, {skipNulls: true})
  const fetch = useQuery({
    queryKey: [invalidateKey, query],
    queryFn: async () => {
      return await axios.get(`${process.env.REACT_APP_API_URL}/${path}?${queryString}`)
    },
    enabled,
    refetchOnWindowFocus: false,
    retry,
  })
  const items: any = fetch.data?.data || []
  return {items, ...fetch}
}

export default useFetch
