// src/store.js
import {configureStore} from '@reduxjs/toolkit'
import categoriesReducer from './categoriesSlice';

export const store = configureStore({
  reducer: {
    categories: categoriesReducer,
  },
  devTools: process.env.REACT_APP_ENV !== 'production', 
})
