import React, {useState} from 'react'
import {DATE_FILTER} from '../../../../app/pages/dashboard/models/_model'
import dayjs from 'dayjs'
import Select from 'react-select'
import InputDatePicker from '@av-partials/forms/InputDatePicker'

const FilterDataDashboard = ({
  setDate = () => {},
  keyProperty,
  date,
  listMachine,
  activeMachine,
  setActiveMachine,
}: {
  setDate: (props: any) => any
  keyProperty: string
  date: any
  listMachine: any
  activeMachine: any
  setActiveMachine: any
}) => {
  const mode = localStorage.getItem('kt_theme_mode_value') || ''
  const [active, setActive] = useState<'year' | 'month' | 'week' | 'normal'>('year')
  const handleChangeDateOption = (arg: 'year' | 'month' | 'week' | 'normal') => {
    setActive(arg)
    setDate((prev: DATE_FILTER) => ({
      ...prev,
      [keyProperty]: {
        ...prev[keyProperty as keyof DATE_FILTER],
        start_at: null,
        end_at: null,
      },
    }))
  }

  const onChangeDateStart = (i: any) => {
    if (dayjs(i).isValid()) {
      setDate((prev: DATE_FILTER) => ({
        ...prev,
        [keyProperty]: {
          ...prev[keyProperty as keyof DATE_FILTER],
          start_at: dayjs(i).endOf('day').toISOString(),
        },
      }))
    } else {
      setDate((prev: DATE_FILTER) => ({
        ...prev,
        [keyProperty]: {
          ...prev[keyProperty as keyof DATE_FILTER],
          start_at: null,
        },
      }))
    }
  }
  const onChangeDateEnd = (i: any) => {
    if (dayjs(i).isValid()) {
      setDate((prev: DATE_FILTER) => ({
        ...prev,
        [keyProperty]: {
          ...prev[keyProperty as keyof DATE_FILTER],
          end_at: dayjs(i).endOf('day').toISOString(),
        },
      }))
    } else {
      setDate((prev: DATE_FILTER) => ({
        ...prev,
        [keyProperty]: {
          ...prev[keyProperty as keyof DATE_FILTER],
          end_at: null,
        },
      }))
    }
  }

  const hasValueDateStart = Boolean(date[keyProperty as keyof DATE_FILTER].start_at)
  const hasValueDateEnd = Boolean(date[keyProperty as keyof DATE_FILTER].end_at)

  return (
    <div className='card-toolbar d-flex justify-content-between align-items-center flex-column flex-sm-row mt-0 card rounded mb-10'>
      <div className=''>
        <div className='d-flex flex-row gap-5'>
          <InputDatePicker
            label={'Start'}
            from='start'
            type={active}
            onChange={onChangeDateStart}
            value={
              hasValueDateStart
                ? dayjs(date[keyProperty as keyof DATE_FILTER].start_at).isValid()
                  ? dayjs(date[keyProperty as keyof DATE_FILTER].start_at).toDate()
                  : null
                : null
            }
          />
          <InputDatePicker
            label={'End'}
            from='end'
            type={active}
            onChange={onChangeDateEnd}
            value={
              hasValueDateEnd
                ? dayjs(date[keyProperty as keyof DATE_FILTER].end_at).isValid()
                  ? dayjs(date[keyProperty as keyof DATE_FILTER].end_at).toDate()
                  : null
                : null
            }
          />
        </div>
        <div className='mt-5'>
          {['Year', 'Month', 'Week', 'Day'].map((item: any) => (
            <div
              key={item}
              onClick={() => handleChangeDateOption(item === 'Day' ? 'normal' : item.toLowerCase())}
              className={`btn btn-sm btn-color-muted btn-active btn-active-primary border border-primary ${
                (item === 'Day' ? 'normal' : item.toLowerCase()) === active && 'active'
              }  px-4 me-1`}
              id='kt_charts_widget_7_year_btn'
            >
              {item}
            </div>
          ))}
        </div>
      </div>
      <div className='mt-sm-0 mt-3'>
        <label className='col-form-label required fw-bold fs-6 text-nowrap'>Select Machine</label>
        <div className='col-lg-8 fv-row'>
          <Select
            value={activeMachine}
            className='basic-single'
            styles={{
              control: (styles: any, {isFocused}: any) => ({
                ...styles,
                fontSize: 14,
                width: '280px',
                border: `1px solid #1e1e2d`,
                backgroundColor:
                  !isFocused && mode === 'dark' ? '#fff' : 'rgba(255, 255, 255, 0.85)',
                color: !isFocused && mode === 'dark' ? '#1b1b29' : 'rgba(255, 255, 255, 0.85)',
              }),
              container: (styles: any, {isSelected}: any) => ({
                ...styles,
                fontSize: 14,
                width: '280px',
                color: '#1b1b29',
                backgroundColor: 'rgba(255, 255, 255, 0.85)',
              }),
              option: (styles: any, {isSelected}: any) => ({
                ...styles,
                fontSize: 14,
                width: '280px',
                color: '#1b1b29',
                backgroundColor: 'rgba(255, 255, 255, 0.85)',
              }),
              menu: (styles: any) => ({
                ...styles,
                backgroundColor: mode === 'dark' ? '#1e1e2d' : 'rgba(255, 255, 255, 0.85)',
              }),
            }}
            isClearable={true}
            isLoading={false}
            onChange={(i: any) => {
              setActiveMachine(i)
            }}
            options={[
              {label: 'Select All', value: null},
              ...listMachine.map((el: any) => ({
                label: el.name,
                value: el.id,
              })),
            ]}
          />
        </div>
      </div>
    </div>
  )
}

export default FilterDataDashboard
