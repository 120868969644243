import axios from 'axios'
import {AuthModel, AuthModelAdmin, AuthModelCompany, UserModel, Response} from './_models'
import API_ENDPOINT from '../../../configs/apiEndpoint'
const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/admin/me`
export const LOGIN_ADMIN_URL = `${API_URL}/auth/admin/login`
export const LOGIN_COMPANY_URL = `${API_URL}/auth/company/login`
export const REGISTER_COMPANY_URL = `${API_URL}/auth/company/register`
export const UPDATE_COMPANY_URL = (id: number) => `${API_URL}/company/${id}`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`
export const SEND_OTP_URL = `${API_URL}/otp/send`
export const VERIFY_OTP_URL = `${API_URL}/otp/verify`

// Server should return AuthModel
export function loginAdmin(email: string, password: string) {
  return axios.post<Response<AuthModelAdmin>>(
    LOGIN_ADMIN_URL,
    {
      email,
      password,
    },
    {
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
  )
}
export function login(email: string, password: string) {
  return axios.post<Response<AuthModelCompany>>(LOGIN_COMPANY_URL, {
    email,
    password,
  })
}

// Server should return AuthModel
export function updateCompany(payload: any) {
  return axios.put(UPDATE_COMPANY_URL(payload.id), payload)
}

export function register(email: string) {
  return axios.post(REGISTER_COMPANY_URL, {
    email,
  })
}

export function sendOTP(email: string, role: string) {
  return axios.post(
    API_ENDPOINT.POST_OTP_SEND,
    {
      email,
      role,
    },
    {
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
  )
}
export function verifyOTP(email: string, otp: string, role: string) {
  return axios.post(
    API_ENDPOINT.POST_OTP_VERIFY,
    {
      email,
      otp,
      role,
    },
    {
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
  )
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string, role: string) {
  return axios.post(
    SEND_OTP_URL,
    {
      email,
      role,
    },
    {
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
  )
}

export function getUserByToken(token?: string) {
  return axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}
