/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
// import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {login, sendOTP} from '../core/_requests'
// import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useAuth} from '../core/Auth'
import {Link, useNavigate} from 'react-router-dom'
import {AuthModelCompany} from '../core/_models'
import {Toaster} from '../../../lib/Toaster'

const loginSchema = Yup.object().shape({
  email: Yup.string().email().required('Email is required'),
  password: Yup.string().required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const [pwdShow, setPwdShow] = useState(false)
  const {saveAuth, setCurrentUser, saveTempAuth} = useAuth()
  const navigate = useNavigate()
  const STORAGE_KEY_OTP = 'timer-otp'

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const response = await login(values.email, values.password)
        const {data: auth} = response
        if (auth?.data?.two_factor_auth === true) {
          const currUser = {
            ...auth?.data,
            token: null,
            user: {
              ...auth?.data?.user,
              image: auth?.data?.user?.image,
              role: 'COMPANY',
            },
          } as AuthModelCompany
          // setCurrentUser({...currUser})
          Toaster({icon: 'success', html: 'Login successfully!', timer: 2000}).then(async () => {
            saveTempAuth({code: auth?.code, message: auth?.message, data: currUser})
            const timeLeft = JSON.parse(
              localStorage.getItem(STORAGE_KEY_OTP) || JSON.stringify({timer: 0})
            )
            if (!timeLeft || timeLeft.timer === 0 || timeLeft.timer === undefined) {
              await sendOTP(values.email, 'COMPANY')
              Toaster({icon: 'success', html: 'OTP was successfully sent!', timer: 2000})
            } else {
              Toaster({icon: 'info', html: 'OTP was sent before, check your inbox!', timer: 2000})
            }
            localStorage.setItem(
              STORAGE_KEY_OTP,
              JSON.stringify({timer: timeLeft.timer ? timeLeft.timer : 180})
            )
            navigate(`/auth/verify?email=${values.email}&role=COMPANY`, {
              replace: true,
            })
          })
          return
        }

        console.log('res', response)
        const currUser = {
          ...auth?.data,
          user: {
            ...auth?.data?.user,
            image: auth?.data?.user?.image,
            role: 'COMPANY',
          },
        } as AuthModelCompany
        // const {data: user} = await getUserByToken(auth?.data?.token)
        Toaster({icon: 'success', html: 'Login successfully!', timer: 1500}).then(() => {
          setCurrentUser({...currUser})
          saveAuth({code: auth?.code, message: auth?.message, data: currUser})
        })
      } catch (error: any) {
        // console.error(error)
        saveAuth(undefined)
        Toaster({icon: 'error', html: error?.response?.data?.message, timer: 1800})
        setStatus(
          error?.response?.data?.message || 'Login gagal! pastikan Email dan password benar!'
        )
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>Sign In</h1>
        <div className='text-gray-500 fw-semibold fs-6'>As Company</div>
      </div>

      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        ''
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
        <input
          placeholder='Your Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='text'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>

      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
        <div className='input-group mb-3'>
          <input
            type={pwdShow ? 'text' : 'password'}
            placeholder='Your Password'
            autoComplete='off'
            {...formik.getFieldProps('password')}
            className={clsx(
              'form-control bg-transparent',
              {
                'is-invalid': formik.touched.password && formik.errors.password,
              },
              {
                'is-valid': formik.touched.password && !formik.errors.password,
              }
            )}
          />
          <div className='input-group-append'>
            <span
              className='input-group-text border-0 rounded-0 cursor-pointer'
              style={{padding: '1.12rem'}}
              onClick={() => setPwdShow(() => !pwdShow)}
            >
              <i className={`fa ${pwdShow ? 'fa-eye' : 'fa-eye-slash'} fs-3`}></i>
            </span>
          </div>
        </div>

        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>

      <div className='d-grid mb-5'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Submit</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}
      <div className='text-gray-500 text-center fw-semibold fs-6'>
        Not a Member yet?{' '}
        <Link to='/auth/registration' className='link-primary'>
          Sign up
        </Link>
        . Forgot password?{' '}
        <Link to='/auth/forgot-password?role=COMPANY' className='link-primary'>
          Click Here!
        </Link>
      </div>
    </form>
  )
}
