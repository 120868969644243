import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {AuthModel, AuthModelCompany, AuthModelAdmin} from './_models'
import * as authHelper from './AuthHelpers'
import {WithChildren} from '../../../../_metronic/helpers'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  tempAuth: any | undefined
  saveTempAuth: (tempAuth: any | undefined) => void
  currentUser: AuthModelAdmin | AuthModelCompany | undefined
  setCurrentUser: Dispatch<SetStateAction<AuthModelAdmin | AuthModelCompany | undefined>>
  logout: () => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  tempAuth: undefined,
  saveTempAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [tempAuth, setTempAuth] = useState<any>(authHelper.getTempAuth())
  const [currentUser, setCurrentUser] = useState<AuthModelAdmin | AuthModelCompany | undefined>()
  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
      window.location.reload()
    } else {
      authHelper.removeAuth()
    }
  }
  const saveTempAuth = (tempAuth: any | undefined) => {
    setTempAuth(tempAuth)
    if (tempAuth) {
      authHelper.setTempAuth(tempAuth)
    } else {
      authHelper.removeTempAuth()
    }
  }

  const logout = () => {
    if (auth?.data?.user.role === 'SUPER_ADMIN') {
      saveAuth(undefined)
      setCurrentUser(undefined)
      window.location.href = '/auth/admin'
    } else {
      saveAuth(undefined)
      setCurrentUser(undefined)
    }
  }

  return (
    <AuthContext.Provider
      value={{auth, saveAuth, currentUser, tempAuth, saveTempAuth, setCurrentUser, logout}}
    >
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const {auth, logout, setCurrentUser} = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    // const requestUser = async (apiToken: string) => {
    //   try {
    //     if (!didRequest.current) {
    //       const {data} = await getUserByToken(apiToken)
    //       if (data?.data) {
    //         setCurrentUser(data)
    //       }
    //     }
    //   } catch (error) {
    //     console.error(error)
    //     if (!didRequest.current) {
    //       logout()
    //     }
    //   } finally {
    //     setShowSplashScreen(false)
    //   }

    //   return () => (didRequest.current = true)
    // }

    // if (auth && auth?.data?.token) {
    //   requestUser(auth?.data?.token)
    // } else {
    //   logout()
    //   setShowSplashScreen(false)
    // }
    // eslint-disable-next-line
    if (auth && auth?.data?.token) {
      // requestUser(auth?.data?.token)
      setCurrentUser(auth?.data)
      setShowSplashScreen(false)
    } else {
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
